$navyblue: #003a6f;
$blue: #007ef2;
$white: #ffffff;
$darkgrey: #6a6a6a;
$blackblue: #36f1cc33;
$horizonblue: #5985ac;
$lightblue: #36f1cc;
$lightgreen: #b7fff1;
$red: #ff2121;
$off-white: #f7f7f7;
$black-pearl: #001221;
$cyan-blue: #3e5265;
// fonts
$font-family-poppins: "Poppins";
* {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}
#dropdown-basic:focus {
  box-shadow: none;
}
// body {
//   position: relative;
//   margin: 0;
//   padding-bottom: 4rem;
//   min-height: 100%;
//   font-family: $font-family-poppins !important;
// }
// footer {
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   //padding: 1rem;
//   //background-color: #efefef;
//   //text-align: center;
//   background-color: $navyblue;
//   color: $white;
//   padding-top: 1rem;
//   font-size: 0.8rem;
// }
body,
html {
  min-height: 100vh;
  font-family: $font-family-poppins !important;
  //overflow: hidden;
}
body.modal-open {
  overflow-y: scroll !important;
  inline-size: 100%;
  position: fixed;
}

// Bootstrap uses JS to set the element style so you can
// override those styles like this
body.modal-open[style] {
  padding-right: 0px !important;
}
.container {
  min-height: 100%;
}
.unAuth-container {
  min-height: calc(100vh - 55px) !important;
  margin: auto;
  max-width: 85%;
}
.word-break {
  word-break: break-all;
}
.App {
  text-align: center;
}
p {
  color: $navyblue;
  font-size: 0.9rem;
}
h3 {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: $navyblue !important;
}
// button:disabled,
// button[disabled] {
//   background-color: #cccccc !important;
//   color: #666666 !important;
// }
.slide-right {
  margin: 2rem -7rem 0 -3rem;
  float: right;
}
.paymentMtd-card {
  height: 150px;
  width: 545px !important;
}
.renewal-history-card,
.account-plan-card {
  //width:1110px !important;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  border-radius: 5px;
  font-size: 0.8rem;
  max-width: 89vw;
  padding: 0px;
}
// .account-plan-card {
//   // height:125px;
//   // width:545px !important;
//   //max-width: 90vh;
// }

.loader-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  text-align: center;
  line-height: 1.6rem;
}
.loader-container .loader-container-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1.5rem;
}
.loader-container small {
  margin: 10px 0 0;
  display: block;
  color: #868e96;
  font-weight: normal;
}
.loader-container h3 {
  text-align: center;
  display: block;
}

.prelogin-card,
.paymentMtd-card {
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px $blackblue;
  border: 1px solid $navyblue !important;
  opacity: 1;
  border-radius: 10px !important;
}
.signin {
  margin-top: 2rem !important;
}
.signup {
  margin: 5rem 7rem !important;
}
.submit-btn {
  border: none !important;
  color: $navyblue !important;
  padding: 0.75rem 5rem !important;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  height: 2.5rem !important;
  font-size: 0.8rem !important;
  background-color: #36f1cc !important;
}
.doc-box {
  min-width: 24px;
  min-height: 26px;
}
.image-login {
  background-image: url("./assets/images/login-img.a6117a8d.png");
  background-size: cover;
}
.login-img {
  margin-bottom: -48px;
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: $navyblue;
}
.form-text {
  font-size: 0.85rem !important;
  color: $navyblue !important;
}

.link-brd {
  text-decoration: none !important;
  border-bottom: 1px solid #0d6efd;
}
.form-control {
  padding: 0.75rem 0.8rem;
  font-size: 0.8rem !important;
  color: $horizonblue !important;
  border: 1px solid $horizonblue !important;
  border-right-style: none !important;
  height: 3.15rem;
}
.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}

.width-auto {
  width: auto !important;
}

.invalid-text {
  color: $red !important;
}
.invalid-input {
  border-color: $red !important;
}
.text-small {
  //letter-spacing: 1px !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  color: $navyblue;
}
.centre-link {
  color: $blue;
  // border-bottom: 1px solid;
  font-size: 0.95rem;
  font-weight: 400;
  text-decoration: none;
  // color: #0d6efd;
}
.cancel-act {
  text-align: left;
  font-size: 16px;
  line-height: 10px;
  letter-spacing: 0.32px;
  color: #007ef2;
  border-bottom: 1px solid #007ef2;
  padding-bottom: 10px;
  width: fit-content;
  cursor: pointer;
}
.cancel-act-txt {
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.28px;
  margin-top: 10px;
  color: #001221;
  width: 54%;
}
.cancel-plan-txt {
  text-align: left;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #3e5265;
}
.cancel-plan-content {
  text-align: left;
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0px;
  margin: 10px 0;
  color: #3e5265;
}
.cancel-account-btns {
  button.info {
    margin-right: 20px;
  }
  button.primary {
    margin-left: 20px;
  }
}
.align-center {
  text-align: center;
}
.align-items-center {
  align-items: center;
}
.align-bottom {
  display: grid;
  align-items: flex-end;
}
.align-right {
  float: right;
}
.justify-flex-end {
  display: flex;
  justify-content: flex-end;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}
.reset-form .resetpassword-btn {
  margin-top: 8rem;
}
.title-heading {
  letter-spacing: 0.4px;
  color: $navyblue;
  opacity: 1;
  font-weight: bold;
  font-size: 1.2rem;
}
.login-boarder {
  display: inline-block;
  width: 80px;
  height: 4px;
  background-color: $lightblue;
  border-radius: 10px;
}
.lb-background {
  background: #dbfff7;
}
.reset-email {
  margin-bottom: 1rem;
}
.password-icon {
  // position: absolute;
  // top: 17rem;
  // right: 2.5rem;
  cursor: pointer;
}
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-right: 0;
}
.profile-dropdwn {
  .dropdown-menu.show:before {
    content: none !important;
  }
  .dropdown-item {
    font-size: 0.8rem;
    padding: 0.5rem 1.2rem;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #1e2125;
    background-color: #ecf6ff;
    border-radius: 5px;
  }
  .dropdown-divider {
    overflow: hidden;
    border-top: 0px solid #9ac2e5;
  }
  .profile-link {
    text-decoration: none;
  }
  .dropdown-toggle {
    margin: auto;
    padding-right: 0px;
  }
}
.primary-drop-down {
  // width: 183px;
  width: 100%;
  height: 250px;
  background: #ffffff;
  box-shadow: 5px 5px 10px #5985ac33;
  border: 1px solid #9ac2e5;
  border-radius: 5px;
  margin-left: auto !important;
  top: 55px;
  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
}

.input-group-text {
  background-color: $white !important;
}
.pwd-icon {
  border-color: $horizonblue !important;
}
.input-group-text :focus {
  background-color: #86b7fe;
}
.main-footer {
  position: fixed;
  bottom: 0;
  background-color: $navyblue;
  color: $white;
  padding-top: 1rem;
  font-size: 0.8rem;
  opacity: 1;
  width: 100%;
}

.main-footer-1 {
  position: fixed;
  background-color: $navyblue;
  color: $navyblue;
  font-size: 0.8rem;
  opacity: 1;
  width: 100%;
  background-color: #e9f2f2;
  bottom: 0;
  left: 0;
  z-index: 1005;
}
.main-footer p {
  color: $white;
}
.main-footer a {
  color: $white;
  text-decoration: none;
}
.main-footer a:hover {
  color: $white;
}

.main-footer-1 p {
  color: $navyblue;
}
.main-footer-1 a {
  color: $navyblue;
  text-decoration: none;
}
.main-footer-1 a:hover {
  color: $navyblue;
}
.footer-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9rem;
  // justify-content: space-evenly;
  gap: 2.5rem;
}

.payment-form select {
  /* word-wrap: normal; */
  width: 100% !important;
  border-color: #5985ac !important;
  color: #5985ac !important;
  padding: 0.5rem 1rem !important;
  font-size: 0.9rem !important;
  border-radius: 5px !important;
}
.subscribe-form select {
  /* word-wrap: normal; */
  width: 100% !important;
  border-color: #5985ac !important;
  color: #5985ac !important;
  padding: 0.8rem 4rem !important;
  font-size: 0.9rem !important;
  border-radius: 3px !important;
}
.reminder-switch-on {
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 0.9rem 1.5rem;
  color: #003a6f !important;
  font-size: 1.02rem !important;
  font-weight: 600 !important;
  margin-bottom: 0;
}
.task-assignee-name {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.reminder-switch-off {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  color: #6a6a6a !important;
  border-radius: 10px;
  padding: 0.9rem 1.5rem;
  font-size: 1.02rem !important;
  font-weight: 600 !important;
  margin-bottom: 0;
}
.sub-form-switch {
  padding: 1rem;
}
// .sub-form-switch .form-check-input {
//   width: 2em !important;
//   height: 0.9em !important;
// }
// .sub-form-switch .form-check-input:checked {
//   background: #C0DCF5 0% 0% no-repeat padding-box;
//   border: 1px solid #003A6F;
// }
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
// sideDrawer
.sidebar {
  position: fixed;
  left: -126px;
  height: 100%;
  width: 200px;
  transition: left 0.3s ease-in-out;
  background-color: $off-white;
  text-align: right;
  //padding: 1rem;
  z-index: 100;
}

.sidebar-toggle {
  position: absolute;
  right: -20px;
  height: 40px;
  width: 24px;
  z-index: 1;
  background-color: $off-white;
  border-radius: 0px 5px 5px 0px;
  border: none;
}
.sidebar-bluebtn {
  border: none;
  background: $navyblue 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 0.4rem 0.5rem;
  margin: 10px 0;
  color: $white;
}
.sidebar-bluebtn span {
  margin: 0 0.3rem;
}
.fmly-navbar {
  margin: 2rem 0;
  background: #ecf6ff;
  padding: 1rem;
  height: 24rem;
}
.fmly-navbar ul {
  padding: 0;
  list-style-type: none;
}
.fmly-navbar ul li img {
  width: 20%;
}
.fmly-navbar ul li {
  padding: 0.5rem 0;
}
.fmly-navbar .list-item {
  font-size: 0.85rem;
  color: #003a6f;
  margin: 0.5rem;
}
.fmly-navbar a {
  text-decoration: none;
}
.sidebar-logout img {
  width: 70%;
}
.sidebar-bottom {
  position: absolute;
  right: 18px;
  bottom: 0;
}
.sidebar-bottom.open {
  right: 0;
  left: 15px;
}
.content,
.profile {
  position: absolute;
  top: 0;
  left: 70px;
  right: 0;
  height: 100%;
  transition: left 0.3s ease-in-out;
  background-color: $white;
  //overflow: auto;
  // text-align: center;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.profile {
  left: -50px;
}

.sidebar.open {
  left: 0;
  text-align: left;
}

.content.open {
  left: 200px;
}
.profile.open {
  left: 55px;
}
// end sideDrawer
//header
.header {
  //border: 1px solid;
  padding-top: 1rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  //overflow: hidden;
  // position: fixed;
  // top: 0;
  //  width: 85%;
  z-index: 3;
  background-color: $white;
  // min-height: 130px;
}
// .profile-avatar {
//   width: 45px;
// }
.profile-avatar {
  //width: 45px;
  // height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 30px;
  border: 2px solid #ecf6ff;
}
.arrow-down {
  width: 10px;
  margin-left: 10px;
}
.page-content {
  // border: 1px solid blue;
  //height: 90%;
  //margin-top: 8rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-bottom: 3rem;
  //overflow-x: hidden;
}
.family-invitation-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 1px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
  //height: 100%;
}
.family-invitation-cardBody {
  min-height: 34.5rem;
}
.small-text {
  color: $horizonblue;
}
.family-invitation-card .small-text {
  color: $horizonblue;
  opacity: 1;
  font-size: 0.9rem;
  letter-spacing: 0;
}
.family-invitation-card .small-blue-text {
  color: #007ef2;
  opacity: 1;
  font-size: 0.85rem;
  margin: 1.5rem 0 4rem;
}
.family-invitation-card img {
  width: 160px;
}
// .family-invitation-leftSection {
//   margin: 2rem 0 1rem;
// }
.family-invitation-leftSection img {
  width: 200px;
}
.family-invitation-leftSection .sub-text {
  letter-spacing: 0.36px;
  color: $black-pearl;
  opacity: 1;
  font-size: 0.9rem;
}
.App-header {
  background-color: #80848b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.flex-row {
  margin-left: 2px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/*-- Subscription page --*/
.subscription-pg .sub-title {
  font-size: 0.75rem;
  color: $black-pearl;
  letter-spacing: 0.28px;
}
.subscription-pg .content-txt {
  letter-spacing: 0.36px;
  color: #001221;
  font-size: 0.9rem;
}
.tab-btn .tab-container {
  border-bottom: 0;
  //background: #ECF6FF 0% 0% no-repeat padding-box;
  //width: 28%;
  height: 40px;
  //border-radius: 36px;
}
.tab-btn .tab-container .nav-link {
  color: $navyblue;
  background-color: #ffffff;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 36px;
  font-size: 0.8rem;
  padding: 0.6rem 4rem;
}
.tab-btn .tab-container .nav-link.active {
  color: $white;
  background-color: #fff;
  background: $navyblue 0% 0% no-repeat padding-box;
  border-radius: 36px;
  font-size: 0.8rem;
  padding: 0.65rem 4.25rem;
}
.tab-btn .nav-tabs .nav-item {
  background: #ecf6ff 0% 0% no-repeat padding-box !important;
  //border-radius: 36px;
}
.tab-btn .nav-tabs .nav-item:first-child {
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
}
.tab-btn .nav-tabs .nav-item:last-child {
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
}
.tab-btn .tab-container .nav-link:hover {
  border-color: #ecf6ff !important;
}
.tab-btn .tab-content {
  padding: 2rem 0;
  box-shadow: none;
}
.subscription-cards .subscription-img img {
  width: 40px;
  height: 40px;
  border-radius: 25px;
}
.choose_plan_modal .card-img img {
  width: 40px;
  height: 40px;
  border-radius: 25px;
}
.choose_plan_modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.choose_plan_modal .modal-body {
  padding-top: 0;
}
.choose_plan_modal .subtext {
  color: #6a6a6a;
  font-size: 0.8rem;
}
.choose_plan_modal .modal-link {
  text-decoration: none;
  border-bottom: 1px solid;
}
.choose_plan_modal .card {
  box-shadow: 3px 3px 10px #e9edf0;
  border-radius: 10px;
  background: #dbfff7;
  border: none;
}
.choose_plan_modal .card-img {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background: #b7fff1 0% 0% no-repeat padding-box;
}
.choose_plan_modal .card-subtext {
  color: #5985ac;
  font-size: 0.85rem;
}
.choose_plan_modal .card-text {
  font-size: 0.8rem;
}
// choose payment method
.choose_paymentMethod_modal .modal-content {
  width: 85%;
  padding: 8px;
  margin-top: 5rem;
}
.choose_paymentMethod_modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.choose_paymentMethod_modal .modal-body {
  padding-top: 0;
}
.card-input-element:checked + .card-input {
  box-shadow: 0 0 1px 1px #2ecc71;
}
.profile_modal .modal-content {
  width: 100%;
  padding: 8px;
  margin-top: 5rem;
  height: 70%;
}
.profile_modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.profile_modal .modal-dialog {
  max-width: 50%;
  height: 590px;
}
.payment_modal .modal-content {
  width: 100%;
  padding: 8px;
  margin-top: 5rem;
}
.highlight-txt {
  color: #007ef2;
}
.payment_modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.payment_modal .StripeElement {
  display: block;
  margin-bottom: 20px;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  border: 1px solid;
  outline: 0;
  border-radius: 4px;
  background: white;
  border-color: #5985ac;
  color: #5985ac !important;
}
.saved-card-element {
  padding-right: 6px;
  max-height: 250px;
  overflow-y: auto;
}
.family-dashbrd .view-text {
  color: #5985ac;
}
.family-dashbrd .view-text a {
  text-decoration: none;
}
.family-dashbrd .view-text-bottom {
  display: none;
}
.family-dashbrd .reminder-text,
.supportingUser-dashbrd .reminder-text {
  color: #007ef2;
  //border: 1px solid #007EF2;
  //border-radius: 25px;
  font-size: 0.8rem;
  //padding: 0.1rem;
}
.family-dashbrd .reminder-text a,
.supportingUser-dashbrd .reminder-text a {
  text-decoration: none;
}
.verification_modal .modal-content {
  margin-top: 5rem;
  //margin-left: 3.5rem;
  // width: 80%;
}
.verification_modal .modal-header {
  border-bottom: none;
  padding: 1rem 1.5rem 0;
}
.styles_react-code-input-container__tpiKG {
  width: 100% !important;
}
.verifictaion-success-msg {
  background: #003a6f 0% 0% no-repeat padding-box;
  border-radius: 15px;
  color: $white;
  padding: 1rem;
  font-size: 0.85rem;
}
input[type="tel"] {
  margin: 0 1rem 0 0;
  border: 1px solid #5985ac !important;
  border-radius: 5px !important;
  // width: 61.5px !important;
  height: 49px !important;
}
.verification-form .invalid-text input[type="tel"] {
  border: 1px solid red !important;
}
.invite_accpt_modal .modal-content {
  margin-top: 5rem;
  margin-left: 3.5rem;
  width: 80%;
}
.invite_accpt_modal .modal-header {
  border-bottom: none;
  padding: 1rem 1.5rem 0;
}
.invite_accpt_modal .modal-body h3 {
  font-size: 1.35rem !important;
}
.invite_accpt_modal .modal-body p {
  padding: 0 5rem;
}
.invite_accpt_modal .modal-body button {
  margin: 1.5rem 0;
}
// .invite_au_modal .modal-content {
//   margin-top: 5rem;
// }
.invite_au_modal .modal-header {
  border-bottom: none;
  //@at-root: 0;
  padding: 1rem 1.5rem 0;
}
.invite_au_modal .subtext {
  color: $cyan-blue;
  font-size: 0.9rem;
}

.doc_modal .pg-viewer-wrapper {
  overflow-y: auto;
}
.doc_modal .pg-viewer-wrapper .document-container {
  width: 1000px;
}
// .invite_au_modal .modal-dialog {
//   width: 35%;
// }
// .doc_modal .modal-dialog {
//   width: 650px !important;
// }
@media (min-width: 576px) {
  // .doc_modal .modal-dialog {
  //     max-width: 650px !important;
  // }
}
.invite_au_modal .modal-body {
  padding: 1rem 1.5rem;
}
.invite_au_modal .separator {
  margin: 1rem 0;
  color: $cyan-blue;
  font-size: 0.9rem;
}
.view_details_modal .modal-header {
  background: #dbfff7;
  height: 6rem;
  padding: 3.5rem 1rem;
}
.view_details_modal .view-header {
  margin-left: 4.5rem;
}
.view_details_modal .view-header-subtext {
  color: #007ef2;
  font-size: 0.8rem;
}
.card-scroll-element::-webkit-scrollbar-track {
  border-radius: 5px;
  border: 3px solid #36f1cc;
}

.card-scroll-element::-webkit-scrollbar {
  width: 1.25rem;
  background-color: #f8f8f8;
  margin-left: 10px;
}

.card-scroll-element::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #9ac2e5;
  box-shadow: inset 0 0 6px #9ac2e5;
  background-color: #36f1cc;
  margin-left: 10px;
  height: 10rem;
}

.famdash-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
  border: 3px solid #36f1cc;
}

.famdash-scroll::-webkit-scrollbar {
  width: 0.75rem;
  background-color: #f8f8f8;
}

.famdash-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #9ac2e5;
  box-shadow: inset 0 0 6px #9ac2e5;
  background-color: #36f1cc;
  margin-left: 10px;
  height: 10rem;
}
.saved-card-empty {
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  margin: 0 0 2rem 0;
  padding: 2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}
.modal-header .btn-close {
  border-radius: 20px;
  width: 7px !important;
  height: 9px !important;
  padding: 0 3px 3px 3px !important;
  margin-top: -15px !important;
}
.page-loader {
  text-align: center;
  height: 220px;
  padding: 5rem;
}
.confrm-div {
  // margin: 5rem 25rem 0 25rem;
  padding: 0 14rem;
  // margin: 5rem 0rem;
}
.confirmation-pg h3 {
  font-size: 1.5rem !important;
}
.confirmation-pg p {
  // padding: 0px 11rem;
  margin: 1rem 0 2rem 0;
  color: $navyblue;
}
.toast {
  // float: right;
  // margin-right: 10rem;
  display: flex;
  justify-content: left;
  margin-top: 0rem;
  color: $white !important;
  background-color: #003a6f !important;
  border: none !important;
}
.fmly-div {
  max-height: 460px;
  min-height: 406px;
  overflow-y: auto;
}
.fmly-invite-div {
  max-height: 400px;
  overflow-y: auto;
}
.fmly-div a {
  text-decoration: none;
}

.error-txt-msg {
  margin-bottom: 0;
  color: red;
  text-align: center;
  font-size: 0.75rem;
  background: #ffe6e6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 0.5rem;
}
.toast-div .toast {
  z-index: 999999999;
  position: absolute;
  top: 3.8rem;
  display: flex;
  left: 42.8%;
  transform: translate(-50%, 0);
}
.toast-div .toast-body {
  padding: 1rem;
  font-size: 0.8rem;
}
.toast-div.error .toast {
  background-color: #e04f5f !important;
}
.invite-history .title {
  color: $black-pearl !important;
}
.loading-btn {
  color: #003a6f !important;
}
.nav-back p {
  background: #dbfff7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: fit-content;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
.nav-back a,
.details-content a {
  color: #003a6f !important;
  text-decoration: none;
}
.nav-back a:hover,
.details-content a:hover {
  text-decoration: none;
}
.fixed-form-header {
  position: relative;
  min-width: 80rem !important;
  max-width: 100rem;
  z-index: 2;
  background-color: $white;
}
.fixed-form-content {
  // margin-top: 10rem !important ;
  position: relative;
  z-index: 1;
}
.warrantList-cards small {
  color: #5985ac;
  font-size: 0.8rem;
}
.supportingUser-dashbrd a {
  text-decoration: none;
}
.warrantList-cards .expired {
  color: #6a6a6a !important;
}
.warrantList-cards .expired-red {
  color: #ff5c5c !important;
}
.warrantList-cards .tab-txt {
  color: #003a6f;
  font-weight: 600;
  font-size: 0.95rem;
}
.warrantList-cards .status {
  color: #007ef2;
}
.warrantList-cards .removed-status {
  color: #6a6a6a;
}
.invite-history .card .card-body {
  padding: 18px 25px;
}
.invite-history .list-btn-revoke p {
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  text-align: center;
  padding: 0.5rem;
  color: #3e5265;
  font-size: 0.8rem;
}
.invite-history .list-btn-removed p {
  background: #dbfff7 0% 0% no-repeat padding-box;
  border: 1px solid #36f1cc;
  border-radius: 36px;
  text-align: center;
  padding: 0.5rem;
  color: #003a6f;
  font-size: 0.8rem;
}
.invite-history .filter-dropdwn {
  float: right;
  background: #dbfff7 0% 0% no-repeat padding-box;
  border-radius: 36px;
  padding: 0.15rem 6.5rem 0.15rem 0.2rem;
}
.warranty-pg .form-select {
  background: #ecf6ff 0% 0% no-repeat padding-box !important;
  border-color: #ecf6ff !important;
}
.viewnotification-label .filter-label {
  padding: 6px 0.5rem !important;
  height: 3rem;
}
.filter-label {
  width: 70px;
  font-size: 0.8rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-right: 0 !important;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 10px !important;
  height: 60px;
  color: #003a6f;
  // margin-left: 1.75rem;
}
.br-round {
  border-radius: 10px !important;
}
.filter-box {
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.filter-value {
  margin-left: 12px;
}

.filter-by {
  margin-right: 12px;
}

.viewnotification .react-select__value-container {
  min-height: auto !important;
  padding-left: 0 !important;
}

.viewnotification .react-select__control {
  min-height: auto !important;
  padding-left: 0 !important;
}

.warranty-pg .css-1s2u09g-control,
.warranty-pg .react-select__value-container {
  border: 0;
  padding-left: 10px;
  font-size: 0.8rem;
  border-top-right-radius: 10px;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-height: 60px;
  color: #003a6f;
}

.warranty-pg .todos-filterdrop .css-1pahdxg-control {
  min-height: auto;
}
.min-height-auto {
  min-height: auto;
}
.warranty-pg .css-1pahdxg-control {
  border: none !important;
  border-color: #ecf6ff;
  box-shadow: none !important;
  font-size: 0.8rem;
  border-top-right-radius: 10px;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-height: 60px;
  color: #003a6f;
}
// .warranty-pg .css-1pahdxg-control:hover{
//   border-color: #ECF6FF;
// }
.warranty-pg .css-1okebmr-indicatorSeparator {
  background-color: #ecf6ff;
}
.warranty-pg .css-319lph-ValueContainer {
  padding: 0;
}
.warranty-pg .css-2613qy-menu {
  color: #003a6f;
  font-size: 0.8rem !important;
}
.warranty-pg .css-tlfecz-indicatorContainer,
.warranty-pg .css-1gtu0rj-indicatorContainer {
  color: #003a6f;
  width: 30px !important;
}
.warrantList-cards .tab-gc-txt {
  letter-spacing: 0.4px;
  color: #003a6f;
  font-size: 1.15rem;
  font-weight: 400;
  margin: 1rem 0 0;
}

.add-warranty-pg .css-1s2u09g-control,
.add-warranty-pg .css-1pahdxg-control {
  border-radius: 0.25rem !important;
  border: 1px solid !important;
  background-color: #fff;
  font-size: 0.8rem !important;
  width: 100%;
  border-color: #5985ac !important;
  color: #5985ac !important;
  height: 3.15rem;
}
.add-warranty-pg .css-1okebmr-indicatorSeparator {
  background-color: #ffff !important;
}
.add-warranty-pg .css-qc6sy-singleValue {
  color: #5985ac !important;
}
.add-warranty-pg .css-tlfecz-indicatorContainer,
.add-warranty-pg .css-1gtu0rj-indicatorContainer {
  color: #003a6f;
  width: 32px !important;
}
.warranty-pg .warrantList-cards {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 1px solid #9ac2e5;
  border-radius: 10px;
}
.equal-box {
  flex: 1;
}
.warranty-pg .warrantList-cards .logo-box {
  margin-left: 8px;
  text-align: left !important;
  width: 90px;
  text-align: center;
}
.warranty-pg .warrantList-cards .Product-type {
  width: 134px;
}

.warranty-pg .category-logo {
  margin-top: -5px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.warranty-pg .card .card-body {
  padding: 18px 25px;
}
.warranty-pg .list-btn p {
  background: #dbfff7 0% 0% no-repeat padding-box;
  border: 1px solid #36f1cc;
  border-radius: 36px;
  opacity: 1;
  padding: 0.5rem;
  width: 12rem;
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0.5rem;
}
.warranty-pg .list-btn a {
  text-decoration: none;
}
.search-input-group {
  border-radius: 10px !important;
}
.search-input {
  background: #ecf6ff 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  border: none !important;
  padding: 1.8rem !important;
  color: #003a6f !important;
  letter-spacing: 0.28px;
  font-size: 14px !important;
  font-weight: bold !important;
}
.search-input-btn {
  background: #ecf6ff 0% 0% no-repeat padding-box !important;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.search-input-btn:focus {
  box-shadow: none !important;
}
.search-input-btn img {
  width: 80%;
  padding: 0.6rem 0;
}
.clear-search {
  background: #ecf6ff 0% 0% no-repeat padding-box !important;
  top: 8px;
}
.clear-search:focus {
  box-shadow: none !important;
}
.clear-search img {
  width: 25px;
  height: 25px;
}
.search-text {
  font-size: 14px;
  line-height: 10px;
  letter-spacing: 0.28px;
}
.search-text span {
  color: #003a6f;
}
.empty-search-text-title {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.4px;
  color: #001221;
}
.empty-search-text-sub-title {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #001221;
}
.filter-dropdwn {
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 0.75rem 0.5rem;
}
.filter-dropdwn .user-dropdown {
  font-size: 0.8rem;
}
.category-btn p {
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 36px;
  opacity: 1;
  width: 7rem;
  text-align: center;
  padding: 0.2rem 0;
  font-size: 0.8rem;
  margin-top: -2rem;
}
.support-user-card {
  background-color: #ecf6ff !important;
}
.support-user-card .subtext {
  color: #001221;
}
.suInvite-dropdwn {
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 36px;
  padding: 0.25rem 1.75rem;
}
.suInvite-dropdwn .inviteTxt {
  font-size: 0.8rem;
  color: #003a6f;
}
.suInvite-dropdwn img {
  width: 15%;
  padding-left: 7px;
}
.suInvite-dropdwn .dropdown-menu.show:before {
  content: none !important;
}
.suInvite-dropdwn .dropdown-menu.show {
  background: #36f1cc 0% 0% no-repeat padding-box;
  inset: -12px auto auto -28px !important;
  border: none;
  box-shadow: none;
  font-size: 0.8rem;
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.suInvite-dropdwn .show {
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 36px;
}
.suInvite-dropdwn .dropdown-item {
  white-space: normal;
  float: left;
  width: 100%;
  height: auto;
  word-wrap: break-word;
  color: #003a6f;
  padding: 1.15rem;
  margin: 0 0.5rem;
  width: 92%;
}
.suInvite-dropdwn .dropdown-item:hover {
  background: #b7fff1 0% 0% no-repeat padding-box;
  border-radius: 12px;
  color: #003a6f;
}
// view warranty details
.view-warranty-pg .view-txt {
  font-size: 1.15rem;
}
.view-warranty-pg h4 {
  font-size: 1.02rem !important;
  font-weight: 600 !important;
  color: $navyblue !important;
}
.add-warranty-pg h4 {
  font-size: 1.02rem !important;
  font-weight: 600 !important;
  color: $navyblue !important;
}
.view-warranty-pg small {
  color: #5985ac;
  font-size: 0.8rem;
}
.view-warranty-pg .view-invoice-txt {
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 10px;
  cursor: pointer;
}
.form-select {
  display: block;
  padding: 0 0.5rem;
  border-radius: 0.25rem !important;
  border: 1px solid !important;
  background-color: #fff;
  font-size: 0.8rem !important;
  width: 100%;
  border-color: #5985ac !important;
  color: #5985ac !important;
  height: 3.5rem;
}
.form-select:focus {
  box-shadow: none;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.add-warranty-pg textarea {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid;
  background-color: #fff;
  font-size: 0.8rem;
  width: 100%;
  border-color: #5985ac;
  color: #5985ac !important;
}

.disable-upload-invoice-btn {
  input[type="file"] {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }
  label {
    background: #003a6f 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: white;
    padding: 0.85rem 1rem;
    width: 100%;
    margin: 2rem 0 0;
    font-size: 0.9rem;
    text-align: center;
    pointer-events: none;
    opacity: 0.4;
  }
}
.add-warranty-pg .upload-invoice-btn {
  input[type="file"] {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }
  label {
    background: #003a6f 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: white;
    padding: 0.85rem 1rem;
    width: 100%;
    margin: 2rem 0 0;
    font-weight: 900;
    font-size: 0.9rem;
    text-align: center;
    cursor: pointer;
  }
}
.doc-file {
  position: absolute;
  top: 38px;
  left: 20px;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 36px;
  margin: auto;
  display: block;
  width: fit-content;
}
.doc-file .icon {
  text-align: left;
  position: relative;
  left: 15px;
  top: 5px;
}
.doc-file .file {
  text-align: left;
  font-size: 14px;
  padding: 0 20px;
  color: #003a6f;
  position: relative;
  top: 5px;
  left: 5px;
}
.doc-file .remove {
  position: relative;
  top: 5px;
  padding-left: 25px;
  padding-right: 10px;
  cursor: pointer;
}
.doc-file .remove img {
  width: 15px;
  height: 15px;
}
.module-list a {
  text-decoration: none !important;
  display: block;
}
.save-compare-pg a {
  text-decoration: none;
}
.save-compare-pg a:hover {
  text-decoration: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.medium-text {
  font: 16px Poppins;
  letter-spacing: 0.4px;
  color: $navyblue;
  opacity: 1;
}
.dark-gray {
  color: $darkgrey;
}
.text-horizonblue {
  color: $horizonblue;
}
.text-black {
  color: $black-pearl;
}
.text-blue {
  color: $blue;
}
.font-small {
  font-size: 11px;
}
.font-medium {
  font-size: 13px;
}
.font-large {
  font-size: 0.8rem;
}
.bold {
  font-weight: bold;
}
.green-circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: $lightgreen;
}
.visa-logo {
  display: flex;
  justify-content: center;
  width: fit-content;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  padding: 5px 10px;
  font-size: 12px;
  border: 5px solid #ecf6ff;
}
.options-icon {
  height: 30px;
  cursor: pointer;
}
.add-warranty-pg .input-group-text {
  padding: 0.1rem 0.3rem;
}
.add-warranty-pg .pound-input {
  color: #003a6f;
  padding: 0.35rem 0.85rem;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 3px;
}
.reminder-flag {
  margin-top: -92px;
  margin-right: 1.5rem;
}
.reminder-flag small {
  font-size: 0.8rem !important;
  color: #9ac2e5 !important;
  letter-spacing: 0.24px;
}
.task-custom-switch {
  padding-top: 2.8rem !important;
}
//---------------------------- pagination --------------------------------------
#page-numbers {
  list-style: none;
  display: flex;
}

#page-numbers > li {
  margin-right: 0.3em;
  color: blue;
  user-select: none;
  cursor: pointer;
}
.reminder-pg .reminder-box {
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 1.5rem;
}
.reminder-pg .reminder-text {
  font-weight: 600;
  font-size: 1rem;
}
.reminder-pg .reminder-subtext {
  color: #6a6a6a;
  font-size: 0.8rem;
}
.ag-header {
  background-color: #003a6f !important;
  border-color: #9ac2e5;
  color: #fff !important;
}
.ag-header-cell {
  color: #fff;
}
.ag-root-wrapper.ag-layout-normal {
  border-radius: 10px !important;
}
.reminder-pg .css-1s2u09g-control {
  border: none;
  font-size: 0.8rem;
  color: #5985ac;
  background-color: transparent;
}
.reminder-pg #react-select-3-listbox {
  width: 100%;
}
.reminder-pg .css-1pahdxg-control {
  border-color: #5985ac;
  font-size: 0.8rem;
  color: #5985ac;
  background-color: #ecf6ff;
}
.reminder-pg .css-2613qy-menu {
  border-color: #5985ac;
  font-size: 0.8rem;
  color: #5985ac;
  background-color: #ecf6ff;
}
.reminder-pg .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
}
.family-dashbrd .accordion {
  width: 210px;
}
.family-dashbrd .accordion-header {
  border-radius: 36px;
}
.family-dashbrd .accordion-item {
  background-color: #36f1cc;
  border: none;
}
.family-dashbrd .accordion-item {
  border-radius: 36px !important;
}
.family-dashbrd .accordion-button,
.family-dashbrd .accordion-button:not(.collapsed) {
  background: #36f1cc 0% 0% no-repeat padding-box !important;
  border-radius: 36px !important;
  color: #003a6f !important;
  font-size: 0.85rem;
  padding: 1rem 2.5rem;
  box-shadow: none;
}
.family-dashbrd .accordion-button::after {
  background-size: 0.9rem !important;
  margin-top: 5px;
}
.family-dashbrd .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
.family-dashbrd .accordion-button:not(.collapsed)::after {
  margin-top: 0px;
}
.family-dashbrd .accordion-body {
  background-color: #36f1cc;
  border-bottom-left-radius: 36px;
  border-bottom-right-radius: 36px;
  margin-top: -14px;
  position: relative;
  z-index: 1;
}
.family-dashbrd .accordion-button:focus {
  box-shadow: none;
}
.family-dashbrd .accordion-body ul {
  list-style-type: none;
  padding: 0;
}
.pos-rel {
  position: relative;
}
.info-position {
  position: absolute;
  top: 0;
  left: 0;
}
.family-dashbrd .accordion-body ul li {
  color: #003a6f;
  font-size: 0.9rem;
  color: #003a6f;
  font-size: 0.85rem;
  padding: 1rem;
}
.family-dashbrd .accordion-body ul li:hover {
  background: #b7fff1 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 1rem;
}
// remiinder page accordion
.reminder-pg .accordion-item {
  border: 1px solid #9ac2e5;
  margin-bottom: 10px;
}
.reminder-pg .accordion-button:not(.collapsed) {
  background-color: $white;
  box-shadow: none;
  color: #9ac2e5;
}
.reminder-pg .accordion-save-btn {
  margin-left: 50rem;
}
// popover delete
.outlined-card #popover-delete {
  width: 300px;
}
.outlined-card #popover-delete .actions {
  padding-top: 20px;
}
.outlined-card #popover-delete .actions .cancel {
  color: red;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}
.outlined-card #popover-delete .actions .confirm {
  color: green;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.loader {
  position: absolute;
  margin: auto;
  display: block;
  left: 175px;
}
.not-found {
  margin: auto;
  display: block;
  text-align: center;
}
.not-found img {
  width: 319px;
  height: 298px;
}
.are-small .is-right {
  display: none !important;
}
//------------------------------ end pagination ----------------------------------
/* iPads (portrait and landscape) ----------- and (max-device-width: 1024px)  */
@media only screen and (min-width: 768px) and (max-device-width: 889px) {
  /* Styles */
  .slide-right {
    margin: 2rem -2rem 0 -15rem;
  }
  .signup {
    margin: 5rem 5rem !important;
  }
  // dashboard
  .family-invitation-leftSection {
    margin: 2rem 2rem 0;
  }
  // SUBSCRIPTION PAGE
  .choose_plan_modal .modal-content {
    width: 100%;
  }
  .choose_paymentMethod_modal .modal-content {
    width: 100%;
    margin-top: 10rem;
  }
  // CONFIRMATION
  .confrm-div {
    padding: 0 5rem;
  }
  // family dashbaord
  .family-dashbrd .welcome-txt {
    margin-bottom: 1rem;
  }
  .warranty-pg .category-logo {
    width: 100%;
  }
  .warranty-pg .list-btn p {
    padding: 0.5rem 0 0.5rem;
    width: 8rem;
    font-size: 0.5rem;
  }
  .warrantList-cards .tab-gc-txt {
    margin: 0;
  }
  .empty-src-img {
    width: 100%;
  }
  .profile_modal .modal-dialog {
    max-width: 72%;
    height: 590px;
  }
  // .filter-label {
  //   margin-left: 0.25rem;
  // }
}

/* Medium screen devices (768px and above) */
// @media only screen and (min-width: 768px) {
//   * {
//     color: red !important;
//   }
// }

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) and (max-device-width: 1024px) {
  // * {
  //   color: blue !important;
  // }
  // .main-view {
  //   min-height: 100vh;
  // }
  .container {
    max-width: 800px !important;
  }
  .page-content {
    overflow-x: hidden;
  }
  // .header {
  //   width: 21%;
  // }
  .slide-right {
    margin: 4rem -5rem 0 -15rem;
    width: 100px;
    height: 100px;
  }
  .login-img {
    width: 450px;
  }
  .signin {
    margin-top: 2.5rem !important;
    margin-left: 1rem;
  }
  .signup {
    margin: 12rem 2rem !important;
  }
  .module-list .col-lg-2 {
    min-width: 25%;
  }
  .fmlyDash-firstRow {
    .col-lg-3 {
      min-width: 35%;
    }
    .col-lg-5 {
      max-width: 40%;
    }
    .col-lg-4 {
      max-width: 25%;
    }
  }
  .family-dashbrd .view-text {
    display: none;
  }
  .family-dashbrd .view-text-bottom {
    display: block;
    margin-bottom: 0;
    color: #5985ac;
  }
  .family-dashbrd .view-text-bottom a {
    text-decoration: none;
  }
  .family-invitation-leftSection {
    .fmly-div {
      min-width: 97%;
      max-height: 383px;
      min-height: 383px;
    }
  }
  .elAccountDash-card {
    .col-lg-4 {
      min-width: 50% !important;
    }
  }
  .subscriptionPlan-card {
    .col-lg-3 {
      min-width: 33% !important;
    }
  }
  .confrm-div {
    padding: 0 5rem;
  }
  .subStats-card {
    .col-lg-2 {
      min-width: 22% !important;
    }
  }
  .subSearch-row {
    .col-lg-6 {
      max-width: 45%;
    }
    .col-lg-2 {
      max-width: 10%;
    }
    .col-lg-4 {
      min-width: 45%;
    }
  }
  .warranty-pg .category-logo {
    margin-top: 3px;
    width: 50px;
    height: 50px;
  }
  .compareAndSave-card {
    .col-lg-2 {
      min-width: 25% !important;
    }
  }
  .fixed-form-header {
    min-width: 51rem !important;
  }
  .details-content {
    .col-lg-4 {
      min-width: 45%;
    }
    .col-lg-3 {
      min-width: 35%;
    }
  }
  .taskView-row {
    .col-lg-8 {
      min-width: 100%;
    }
  }
  .viewNotification-row {
    .col-lg-4 {
      min-width: 45%;
    }
  }
  .profileContact-row {
    .col-md-3 {
      min-width: 35%;
    }
    .col-md-9 {
      max-width: 64%;
    }
  }
  .reset-form input[type="tel"] {
    width: 37.5px !important;
    height: 40px !important;
  }
  .support-user-card {
    .col-lg-3 {
      min-width: 40%;
    }
  }
  .supportingUserDash-row {
    .col-lg-3 {
      min-width: 35%;
    }
  }
  .empty-src-img {
    width: 100% !important;
  }
  .primary-drop-down {
    width: 175px;
    height: 150px;
    left: -60px;
  }
}

.family-details-main {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.family-details-main-familyname {
  margin-right: 20px;
  //margin-top: 5px;
  font-size: larger;
  font-weight: 500;
  display: inline-block;
}
.family-details-main-familyid {
  font-size: 0.8rem;
  height: 27px;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  /* border: 1px solid white; */
  background-color: #ecf6ff;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  /* padding-top: 7px; */
  /* height: 30px; */
  width: fit-content;
  /* width: fit-content; */
  padding: 5px 10px;
}
.familyDisplayName {
  font-size: 0.7rem;
  margin-right: 5px;
}
.familyDisplayId {
  font-size: 0.7rem;
  font-weight: 500;
  color: #007ef2;
  margin-right: 5px;
}

.family-abbr {
  float: left;
  background: #003a6f 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: white;
  padding: 9px 15px;
  margin-top: 13px;
}

.familyDisplayId-FamilyDashboard-profile {
  font-size: 0.8rem;
  border: 1px solid white;
  background-color: #ecf6ff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  .familyDisplayId {
    font-weight: 500;
    color: #007ef2;
  }
}

.DisplayId-FamilyListCard {
  font-size: smaller;
  padding-left: 10px;
  position: absolute;
  // top: 10px;
  right: 10px;
  padding-right: 10px;
  border: 1px solid white;
  background-color: white;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  // padding-top: 5px;
  // height: 30px;
  width: fit-content;
  justify-content: end;
  // @media only screen and (max-width: 1210px ) and (min-width: 992px ) {
  //   height: 45px;
  // }
  // @media only screen and (max-width: 557px ) {
  //   height: 50px;
  // }
  .familyDisplayId {
    font-weight: 500;
    color: #007ef2;
  }
}

.User-Role-In-Family {
  color: #003a6f;
  font-size: 0.8rem;
  margin-top: 3px;
  margin-bottom: 15px;
  margin-left: 55px;
}
.Main-EL-dropdown-UserId {
  font-size: 0.8rem;
  // padding: 0.5rem 1.2rem;
  // margin: 0 0.8rem;
  // width: 86%;
  border: 1px #9ac2e5 solid;
}
.EL-dropdown-UserId {
  display: flex;
  flex-direction: row;
  background-color: #ecf6ff;
  padding: 10px 0;
  justify-content: center;
  .familyDisplayId {
    font-weight: 500;
    color: #007ef2;
  }
}
.Plan-details-row {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  margin-left: 55px;
  //   margin-right: 150px;
}
.Plan-details-row-txt {
  font-size: 0.7rem;
  color: #707070;
  margin-bottom: 3px;
  margin-right: 20px;
}
.Plan-details-row-txt-value {
  font-size: 0.8rem;
  color: #003a6f;
  margin-bottom: 7px;
}
.button-CopyToClipboard {
  border: none;
  background-color: white;
}
.button-CopyToClipboard1 {
  border: none;
  background-color: #ecf6ff;
}
.button-CopyToClipboard svg {
  width: 10px;
  height: -moz-fit-content;
  /* height: 10px; */
  /* padding-top: 5px; */
  // margin-bottom: 8px;
}
.button-CopyToClipboard1 svg {
  width: 10px;
  height: -moz-fit-content;
  /* height: 10px; */
  /* padding-top: 5px; */
  // margin-bottom: 8px;
}

.SUDashboard-email {
  color: #707070;
  font-size: 0.7rem;
  margin-bottom: 15px;
  margin-top: 3px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #888;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.main-button-row {
  display: flex;
  flex-direction: row;
}
.button-row-left {
  margin-right: 50px;
}
.button-row-right {
  margin-right: 235px;
}

.image-document-size {
  width: -webkit-fill-available;
}

.display-class {
  display: none;
}

.content-open1 {
  left: 0px;
}

.fmly-card-details {
  height: 86px;
}

.sizing-properly {
  height: 565px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

// /* Extra big screen devices (1200px and above) */
// @media only screen and (min-width: 1200px) {
//   * {
//     color: green !important;
//   }
// }

.warranties-option {
  z-index: 1;
  position: relative;
}

.numbercard-div-style {
  border: 1px solid #ecf6ff;
  background-color: #dbfff7;
  // height: 74px;
  border-radius: 5px;
  // margin-left: 10px;
  // height: 100px;
  // width: 200px;
  // margin-right: 25px;
}

.numbercard-div-style-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.warranty-doc-btn {
  width: fit-content;
  background: 0% 0% no-repeat padding-box padding-box rgb(219, 255, 247);
  border: 1px solid rgb(54, 241, 204);
  border-radius: 50px;
  padding: 13px 29px;
  cursor: pointer;
}

.warranty-doc-btn2 {
  width: fit-content;
  background: #dbfff7 0% 0% no-repeat padding-box;
  border: 1px solid #36f1cc;
  border-radius: 50px;
  padding: 13px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-size-wrrnty {
  margin-right: 40px;
}

.fhs-vh {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fhs-vh-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.su-newstyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 57px;
}

.fhs-vh-2 {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.icon-user {
  width: 25px;
  /* margin-right: 0%; */

  /* margin-right: 6%; */

  height: 30px;
  // cursor: pointer;
  //  padding-top: 0.75rem;
}
.icon-user-1 {
  width: 12px;
  /* margin-right: 0%; */
  // right: 100px;
  // height: 46px;
  /* margin-right: 6%; */
  cursor: pointer;
  // position: absolute;
}
.icon-user-2 {
  width: 20px;
  /* margin-right: 0%; */
  position: absolute;
  right: 30px;
  height: 40px;
  /* margin-right: 6%; */
  cursor: pointer;
}
.PU-dsn {
  // background: #36f1cc 0% 0% no-repeat padding-box;
  background: #ecf6ff 0 0 no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-top: 1rem;
}

.PU-dsn-sub {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.PU-dsn-txt {
  color: #003a6f;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.PU-dsn-txt-1 {
  color: #003a6f;
  font-size: 0.9rem;
  margin: 0;
}

.PU-dsn-txt-1-1 {
  color: #707070;
  font-size: 0.8rem;
  margin: 0px 57px 0px 14px;
}

.main-dgn {
  width: 10px;
  position: absolute;
  right: 20px;
  /* display: contents; */
  margin-left: 10px;
  background-color: #ecf6ff;
  border: none;
  height: -18px;
}

.text-dsgn {
  padding: 1rem 0;
  font-size: 0.75rem;
  color: #007ef2;
}

.main-dsgn-text {
  display: flex;
  flex-direction: row;
}

.warranty-doc-btn1 {
  background: #dbfff7 0% 0% no-repeat padding-box;
  border: 1px solid #36f1cc;
  border-radius: 50px;
  padding: 13px;
  cursor: pointer;
  padding-left: 29px;
}

.main-div-dsn {
  background: #dbfff7 0% 0% no-repeat padding-box;
  padding: 0.5rem 0.9rem;
  border-radius: 5px;
  opacity: 1;
  border: none;
}

.main-div-dsn-1 {
  background: #fefefe 0% 0% no-repeat padding-box;
  padding: 0.5rem 0.9rem;
  border-radius: 5px;
  opacity: 1;
  border: none;
  // border: 2px solid #9AC2E5;
  outline: 2px groove #9ac2e5;
  outline-offset: -2px;
}
// #react-select-3-listbox{
//   width: 120px;
// }
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2 !important;
}
.flex-05 {
  flex: 0.5 !important;
}
.warranty-pg .todos-filterdrop .css-1s2u09g-control {
  min-height: auto;
}
.align-elements > div {
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
}
.wid-50 {
  width: 132px !important;
  word-break: break-all;
}

.demo-test {
  width: 162px !important;
  word-break: break-all;
}
.wid-50 strong {
  line-height: 20px;
}
.call2actionbtns {
  flex: none !important;
  width: 200px;
}
.flex-no {
  flex: none !important;
}
.flex-05 {
  flex: 0.5 !important;
}
.text-dsn-outgoing-kpi {
  margin: 0;
  color: #003a6f;
  font-size: 0.7rem;
  padding-left: 30%;
  font-weight: bolder;
}

.text-dsn-outgoing-kpi-section {
  margin: 0;
  color: #003a6f;
  font-size: 0.7rem;
}

.cost-dsn-outgoing-kpi {
  color: #040008;
  margin: 0.25rem 0;
  font-weight: 600;
  font-size: 1rem;
}

.sub-div-dsn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sidebar-sizing-VouGift {
  display: flex;
  flex-direction: row;
}

.main-auth-dsgn .modal-content {
  width: max-content;
  left: -3.8rem;
  height: 125px;
  /* UI Properties */
  border: 1px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
  padding: 1.5rem 1rem 2rem;
}

.new-auth-dsgn .modal-content {
  width: fit-content;
}

.wizard-auth-dsgn .Modal.Title {
  text-align: center;
}

.auth-dsgn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.left-auth-dsgn {
  /* Layout Properties */
  top: 83px;
  left: 363px;
  width: 344px;
  height: 42px;
  /* UI Properties */
  color: var(--unnamed-color-003a6f);
  text-align: left;
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0.28px;
  color: #003a6f;
}

.right-auth-dsgn {
  /* Layout Properties */
  top: 84px;
  left: 727px;
  width: 244px;
  height: 40px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-36f1cc);
  background: #dbfff7 0% 0% no-repeat padding-box;
  border: 1px solid #36f1cc;
  border-radius: 36px;
  opacity: 1;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
}

.right-auth-dsgn-1 {
  top: 84px;
  left: 727px;
  width: 260px;
  font-weight: 600;
  height: 40px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-36f1cc);
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border: 1px solid #ecf6ff;
  border-radius: 36px;
  opacity: 1;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  color: #003a6f;
}

.wizard-pu-dsgn {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #36f1cc;

  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* -webkit-appearance: none; */
  appearance: none;
  cursor: pointer;
}

.button-wizard {
  width: 170px;
  height: 2.75rem;
  font-size: 0.85rem;
  padding: 0.5rem 25px;
  font-weight: 600;
  margin-top: 1rem;
  border-radius: 2rem;
  margin-left: 9rem;
  background-color: #36f1cc;
  color: #003a6f;
  border: none;
}

.div-wizard {
  margin-bottom: 1rem;
  border: 1px solid;
  border-radius: 2rem;
}
.div-dsgn-wizard {
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}

.wizard-main-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 1px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
  padding-bottom: 1rem;
}

.wizard-div-sub {
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
  justify-content: space-between;
}

.wizard-div-sub1-1 {
  //  width: 29rem;
  // margin-top: 7px;
  color: var(--unnamed-color-003a6f);
  text-align: left;
  font: normal normal bold 20px/30px Poppins;
  letter-spacing: 0px;
  color: #003a6f;
  opacity: 1;
  // margin-right: 12px;
}

.wizard-div-sub1-2 {
  margin-right: 12px;
  padding: 0.5rem 2rem;
  // width: 98px;
  height: 45px;
  /* UI Properties */
  border: 1px solid #007ef2;
  border-radius: 22px;
  opacity: 1;
  // margin-top: 10px;
  background-color: white;
  color: #007ef2;
}

.wizard-div-sub1-2-after {
  height: 45px;
  padding: 0.5rem 2rem;
  // margin-right: 1rem;
  // width: 98px;
  // height: 43px;
  /* UI Properties */
  border: 1px solid #007ef2;
  border-radius: 50px;
  opacity: 1;
  margin-right: 12px;
  background-color: #007ef2;
  // margin-top: 10px;
  color: white;
}

.wizard-div-sub1-3 {
  padding: 0.5rem 2rem;
  // width: 92px;
  /* UI Properties */
  border: 1px solid #007ef2;
  border-radius: 50px;
  opacity: 1;
  height: 45px;
  // height: 43px;
  // margin-left: 1rem
  background-color: white;
  color: #007ef2;
}

.wizard-div-sub1-3-after {
  padding: 0.5rem 2rem;
  border: 1px solid #007ef2;
  background-color: #007ef2;
  border-radius: 22px;
  opacity: 1;
  /* margin-top: 10px; */
  color: white;
  height: 2.8rem;
}

// .Wizard-SU-dsn{
//   // display: flex;
//   // flex-direction: row;
//     height: 81px;
//     background: #ecf6ff 0% 0% no-repeat padding-box;
//     border-radius: 5px;
//     opacity: 1;
//     padding: 1rem;
//     margin-top: 1rem;
//     margin-left: 4rem;
//     margin-right: 4rem;
// }

.wizard-Container {
  margin-left: 2rem;
  margin-right: 2rem;
}

.wizard-SU {
  display: flex;
  flex-direction: row;

  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 1rem;
  margin-top: 1rem;

  justify-content: space-between;
  // width: 27.2rem;
  margin-right: 217px;
}

.wizard-SU-1 {
  // width: 27.2rem;
  display: flex;
  flex-direction: row;
  // height: 81px;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 1rem;
  margin-top: 1rem;
  // margin-left: 2rem;
  margin-right: 217px;
}

.bttn-wizard {
  top: 926px;
  left: 458px;
  width: -webkit-fill-available;
  height: 53px;
  /* UI Properties */
  background: var(--unnamed-color-36f1cc) 0% 0% no-repeat padding-box;
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 36px;
  opacity: 1;
  margin: 0.5rem 2rem;
  border: 1px;
}

.new-auth-dsgn-look .modal-content {
  width: fit-content;
  position: absolute;
  /* left: -156px; */
  right: -330px;
}

.copy-dsgn {
  font-size: 13px;
  font-size: 12px;
  height: 27px;
  /* display: flex; */
  flex-direction: row;
  width: -moz-fit-content;
  margin-right: 1rem;
  padding: 5px 5px 4px 5px;
}

.copy-dsgn-Dashboard {
  font-size: 0.7rem;
  float: right;
  margin-top: -1.5rem;
  margin-right: 20px;
}

.profile-dsgn {
  display: flex;
  flex-direction: row;
}

.toastDsgn {
  //margin-right: 0 !important;
  z-index: 999999999;
  position: absolute;
  left: 30%;
  top: 3rem;
}

.btnWizard {
  padding: 1rem 2.5rem;
  border-radius: 10rem;
  background-color: #36f1cc;
  color: #003a6f;
  // font-size: 25px;
  // font-weight: 600;
  font: normal normal medium 18px/20px Poppins;
  border: 0;
  cursor: pointer;
  margin-bottom: 8rem;
  opacity: 1;
}

.wizardEmail {
  display: block;
  margin: 1rem 2rem;
}

.invite-form {
  // display: flex;
  justify-content: space-between;
  align-items: center;
}

.wizardText {
  /* margin-right: 0rem; */
  /* font-size: 18px; */
  /* margin-left: -31px; */
  padding: 0.5rem 1rem;
  font: normal normal bold 20px/30px Poppins;
  text-align: left;
  letter-spacing: 0px;
  color: #003a6f;
  margin-top: 0.5rem;
}

.wizardEmailTxt {
  border: 1px solid #0000ff !important;
  border-radius: 5px;
  padding: 1rem;
  width: 27.1rem;
}

.wizardAddBtn {
  padding: 0.5rem 0rem;
  border: none;
  background: #36f1cc 0 0 no-repeat padding-box !important;
  color: black;
  height: 42px;
  border-radius: 35px;
  width: 12.11rem;
}

.dltWizard {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.Main-Tab {
  // display: flex;
  // flex-direction: row;
  // // justify-content: space-around;

  // margin-top: 1rem;
  // padding: 1rem;

  border-bottom: 1px solid #9ac2e5;
  height: 2.5rem;
}

.Main-Users-Tab {
  padding: 0.5rem;
  font-size: 20px;
  font-weight: 500;
}

.Support-Users-Tab {
  padding: 0.5rem;
  font-size: 20px;
  font-weight: 500;
}

.Bottom-Border-Wizard {
  position: absolute;
  top: 12.8rem;
  height: 5px;
  width: 23rem;
  background-color: #36f1cc;
  left: 17.7rem;
}

.Bottom-Border-Wizard-1 {
  position: absolute;
  top: 12.8rem;
  height: 5px;
  width: 23rem;
  background-color: #36f1cc;
  left: 41.7rem;
}

.SU-Top-1 {
  color: #007ef2;
  margin-top: 2rem;
  /* margin-left: 0; */
  text-align: start;
  margin-bottom: 2rem;
  // margin-left: 1rem;
  display: flex;
  flex-direction: row;
}

.bttn-wizard-1 {
  top: 926px;
  left: 458px;
  width: fit-content;
  height: 53px;
  /* UI Properties */
  background: var(--unnamed-color-36f1cc) 0% 0% no-repeat padding-box;
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 36px;
  opacity: 1;
  margin: 0.5rem 2rem;
  border: 1px;
  color: #003a6f;
  padding: 0px 3rem;
}

.newBtnWZ {
  // margin-top: 15px;
  margin-right: 10px;
}

.ABC {
  width: 5px;
}

.switch {
  position: relative;
  /* display: inline-block; */
  // width: 78px;
  // height: 25px;
  // top: 1.4rem;
  // right: 1.15rem;
}

.switch input {
  display: none;
}
.filter-bg > div {
  flex: 1;
}

.filter-bg > .no-flex {
  flex: none;
}
.family-name {
  overflow: hidden;
  margin-right: 0px !important;
  text-overflow: ellipsis;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.text-height-align {
  overflow: hidden;
  font: normal normal 600 14px/10px Poppins;
  line-height: 20px;
  text-overflow: ellipsis;
  height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  margin-top: 8px;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.slider {
  cursor: pointer;
  width: 78px;
  height: 25px;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  top: 0.38rem;
}

input:checked + .slider {
  background-color: #2ab934;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: "OFF";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 43%;
  font-size: 11px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
  content: "ON";
}

/*--------- END --------*/

.checkBoxVal {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  right: 13px;
  cursor: pointer;
}

.main-Card-FD {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
  padding: 1rem;
}

.card-FD-Title {
  margin: 1rem 0 0;
  color: #003a6f;
  font-size: 1rem;
  font-weight: 600;
}

.card-FD-email {
  margin: 0.15rem 0;
  color: #5985ac;
}

.card-FD-date {
  margin-top: 1.15rem;
  color: #007ef2;
  font-size: 0.8rem;
}

.main-box {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1.5rem;
  color: #003a6f;
  font: normal normal bold 18px/10px Poppins;
  padding-bottom: 2rem;
  padding-top: 1.5rem;
}

.line-1 {
  width: 15.3rem;
  border-top: 1px solid #36f1cc;
  position: absolute;
  left: 2.8rem;
  top: 12.5rem;
  border-left: 1px solid #36f1cc;
  height: 1.5rem;
  border-radius: 10px 0px;
}

.line-2 {
  width: 17.3rem;
  border-top: 1px solid #36f1cc;
  position: absolute;
  left: 36rem;
  top: 12.5rem;
  border-right: 1px solid #36f1cc;
  height: 1.5rem;
  border-radius: 0px 10px;
}

.line-3 {
  width: 4.3rem;
  border-top: 1px solid #36f1cc;
  position: absolute;
  left: 55.2rem;
  top: 12.5rem;
  border-left: 1px solid #36f1cc;
  height: 1.5rem;
  border-radius: 10px 0px;
}

.line-4 {
  width: 5.3rem;
  border-top: 1px solid #36f1cc;
  position: absolute;
  left: 74.3rem;
  top: 12.5rem;
  border-right: 1px solid #36f1cc;
  height: 1.5rem;
  border-radius: 0px 10px;
}

.newbuttonFD {
  background: #36f1cc 0 0 no-repeat padding-box !important;
  border-radius: 36px !important;
  color: #003a6f !important;
  font-size: 0.85rem;
  padding: 1.1rem 3.25rem;
  box-shadow: none;
  cursor: pointer;
  width: 14.5rem;
  display: block;
}

.newbuttonFD-1 {
  text-align: center;
  background: #36f1cc 0 0 no-repeat padding-box !important;
  border-radius: 36px !important;
  color: #003a6f !important;
  font-size: 0.85rem;
  padding: 1.1rem 2.75rem;
  box-shadow: none;
  cursor: pointer;
  width: 14.5rem;
}

.tab-line-1 {
  padding-left: 17rem;
  padding-right: 17rem;
}

.tab-line-2 {
  padding-left: 100px;
  padding-right: 100px;
}
.bttnn-wizard {
  width: 25%;
  height: 53px;
  background: var(--unnamed-color-36f1cc) 0% 0% no-repeat padding-box;
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 36px;
  opacity: 1;
  margin: 0.5rem 2rem;
  border: 1px;
  font-weight: 800;
}

.AO-RB {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
  padding: 1.4rem;
  margin-bottom: 1rem;
  width: 70%;
  height: 75px;
  cursor: pointer;
}

.AO-RB-1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
  padding: 1.4rem;
  margin-bottom: 1rem;
  width: 70%;
  height: 68px;
  cursor: pointer;
}

.AO-RB-Text {
  text-align: left;
  font: normal normal 600 15px/10px Poppins;
  letter-spacing: 0px;
  color: #001221;
  opacity: 1;
}

.Reminder-familyName {
  text-align: left;
  font: normal normal 600 20px/10px Poppins;
  letter-spacing: 0.4px;
  color: #001221;
  opacity: 1;
  height: 35px;
}

.Reminder-backBtn {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #001221;
  opacity: 1;
  border: 2px solid #dbfff7;
  /* left: 128px; */
  width: 20rem;
  height: 3rem;
  background-color: #dbfff7;
  padding: 10px;
  margin-bottom: 1.8rem;
  cursor: pointer;
  border-radius: 5px;
}

.Reminder-backBtn-1 {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #001221;
  opacity: 1;
  border: 2px solid #dbfff7;
  /* left: 128px; */
  width: 20rem;
  height: 3rem;
  background-color: #dbfff7;
  padding: 10px;
  margin-bottom: 1.25rem;
  cursor: pointer;
  border-radius: 5px;
}

.Reminder-AllRem {
  width: 279px;
  height: 28px;
  color: var(--unnamed-color-003a6f);
  text-align: left;
  font: normal normal bold 20px/10px Poppins;
  letter-spacing: 0.4px;
  color: #003a6f;
  opacity: 1;
  margin-bottom: 5px;
  margin-top: 7px;
}

.Reminder-AllRem-Text {
  width: 771px;
  height: 20px;
  text-align: left;
  font: normal normal normal 14px/10px Poppins;
  letter-spacing: 0.28px;
  color: #001221;
  opacity: 1;
}

.Reminder-AllRem-Main {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
}

.Reminder-AllRem-Main-1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.Reminder-AllRem-Btn {
  width: 251px;
  height: 53px;
  background: var(--unnamed-color-36f1cc) 0% 0% no-repeat padding-box;
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 36px;
  opacity: 1;
  padding: 14px 24px;
  cursor: pointer;
}

.Reminder-AllRem-Btn-Txt {
  letter-spacing: 0px;
  color: #003a6f;
  opacity: 1;
  font-size: 15px;
  text-decoration: none;
}

.Reminder-Table-1 {
  color: var(--unnamed-color-003a6f);
  text-align: left;
  font: normal normal bold 20px/10px Poppins;
  letter-spacing: 0.4px;
  color: #003a6f;
  opacity: 1;
}

.Reminder-Filter {
  position: absolute;
  width: 206px;
  height: 37px;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  left: 18rem;
  top: 16.5rem;
  padding: 9px 18px;
  font-size: 14px;
  font-weight: 600;
}

.AU-Rem-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AU-Rem-1-sub-1 {
  width: 100px;
  height: 25px;
  text-align: left;
  font: normal normal bold 18px/10px Poppins;
  letter-spacing: 0px;
  color: #001221;
  opacity: 1;
  margin-top: 5px;
  display: flex;
}

.AU-Rem-1-sub-img {
  position: absolute;
  left: 8rem;
  top: 0.1rem;
}

.AU-Rem-1-sub-2 {
  width: 61px;
  height: 23px;
  text-align: right;
  font: normal normal normal 14px/10px Poppins;
  letter-spacing: 0px;
  color: #5985ac;
  opacity: 1;
  margin-top: 5.5px;
  cursor: pointer;
  margin-right: 2%;
}

.AU-Rem-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #36f1cc;
  border-radius: 10px;
  opacity: 1;
  padding: 1rem 1rem 1rem 0rem;
  cursor: pointer;
  margin-bottom: 1rem;
  position: relative;
}

.AU-Rem-2-Txt {
  text-align: left;
  font: normal normal normal 12px/10px Poppins;
  letter-spacing: 0px;
  color: #5985ac;
  opacity: 1;
}

.AU-rem-2-Txt-Sub {
  text-align: left;
  font: normal normal 600 14px/10px Poppins;
  letter-spacing: 0px;
  line-height: 16px;
  color: #001221;
  opacity: 1;
  margin-top: 10px;
}

.AU-Rem-3 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  // position: absolute;
  margin-top: 6px;
  margin-left: 2rem;
}
.module-list > div {
  height: 100%;
  // position: relative;
  // max-width: 25%;
  // align-self: stretch;
  // width: 100%;
  // padding-right: 15px;
  // padding-left: 15px;
  // display: flex;
}

.AU-Rem-2-Img-1 {
  position: absolute;
  top: 6px;
  left: 8px;
}

.btn-outgoing-edit {
  height: 2.75rem;
  font-size: 0.85rem;
  padding: 0.75rem 50px;
  background-color: #36f1cc;
  width: 240px;
  font-weight: 500;
  border-radius: 10rem;
  border: none;
  color: #003a6f;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.85rem;
  cursor: pointer;
}

.rem-show {
  overflow-y: scroll;
  height: 22.9rem;
  margin: 1rem 0;
  padding: 0 0.5rem;
  // position: absolute;
  // width: 25rem;
}

.showfilter {
  visibility: visible;
}

.hidefilter {
  visibility: hidden;
}

.loadind-page {
  display: flex;
  align-items: center;
  // background-color: red;
  justify-content: center;
  height: 80vh;
}
.loadind-page-table {
  display: flex;
  align-items: center;
  // background-color: red;
  justify-content: center;
  height: 40vh;
}

.no-rem {
  // width: 525px;
  height: 24.9rem;
  opacity: 1;
  // position: absolute;
}

.no-rem-1 {
  position: absolute;
  top: 12.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.no-rem-2 {
  width: max-content;
  position: absolute;
  top: 15.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
}

.Filter-rem {
  display: flex;
  width: 380px;
  /* height: 26px; */
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 600;
}

.Filter-rem-1 {
  margin-left: 17px;
  /* margin-bottom: 44px; */
  border: none;
  background-color: #ecf6ff;
}

.Filter-rem-2 {
  display: flex;
  width: 380px;
  /* height: 26px; */
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  // height: 2rem;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 600;
}

.hello-el-fd {
  color: #5985ac;
  font-size: 0.85rem;
  margin: 17px 0 0px 0;
}

.new-Txt-Chng {
  letter-spacing: 0px;
  color: #003a6f;
  opacity: 1;
  display: flex;
  justify-content: center;
  font-size: 14px;
  background: #ECF6FF 0 0 no-repeat padding-box;
  border-radius: 5px;
  /* opacity: 1; */
  padding: 1rem;
  margin-top: 1rem;
}

.border-radius-rignt-none {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

@media (min-width: 1399.98px) {
  .no-remVal {
    margin-top: 12px;
    height: 18.5rem;
  }
  .rem-showval {
    overflow-y: scroll;
    height: 17rem;
    margin: 1rem 0;
    padding: 0 0.5rem;
  }
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
  .action-right.number-txt {
    text-align: center;
    margin-bottom: 0;
  }
  .no-rem {
    margin-top: 12px;
    height: 24.1rem;
  }
  .no-remVal {
    margin-top: 12px;
    height: 24rem;
  }
  .rem-showval {
    overflow-y: scroll;
    height: 23.25rem;
    margin: 1rem 0;
    padding: 0 0.5rem;
  }
  .default-plan-img {
    margin-left: auto;
    margin-right: auto;
  }
  .sidebar {
    width: 200px;
  }
  .content.open {
    left: 200px;
  }
  .no-rem-1 {
    position: absolute;
    top: 12.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .no-rem-2 {
    width: max-content;
    position: absolute;
    top: 15.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
  }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1359.98px) {
  .wizard-SU {
    margin-right: 0 !important;
  }
  .wizard-SU-1 {
    margin-right: 0 !important;
  }
}

@media (max-width: 1340px) {
}

.card-del-2 {
  display: flex;
  margin: 2rem 0rem;
}

.card-del-2-1 {
  margin-right: 2rem;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.card-del-2-2 {
  margin-right: 2rem;
  box-shadow: 3px 3px 10px #e9edf0;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.card-del-3 {
  display: flex;
  float: right;
  cursor: pointer;
}

.card-del-3-1 {
  // border: 2px solid #9ac2e5;
  // border-radius: 10rem;
  padding: 1rem;
  margin-right: 2rem;
}

.card-del-3-2 {
  border: 2px solid #9ac2e5;
  border-radius: 10rem;
  padding: 1rem;
  background-color: #e9edf0;
}

.animesh .btn-close {
  position: absolute;
  right: 2.25rem;
  top: 3.5rem;
}

.update-add-btn .mediumbtn {
  background: #003a6f 0% 0% no-repeat padding-box;
  // border-radius: 3px;
  color: white;
  // padding: 0.85rem 1rem;
  // width: 100%;
  // margin: 2rem 0 0;
  // font-size: 0.9rem;
  // text-align: center;
  pointer-events: none;
  opacity: 0.4;
}

.notification-users {
  width: 15rem;
}

.notification-users .flex-1 {
  width: 12.5rem;
}

.main-box-downSub {
  border: 1px solid #dbfff7;
  padding: 12.5px;
  margin-bottom: 1rem;
  background: #dbfff7 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border-radius: 10px;
  opacity: 1;
}

.text-1-downSub {
  color: black;
  font-weight: 700;
}

.text-2-downSub {
  color: #003a6f;
  font-size: 12px;
  padding: 3px 15px;
  border: 1px solid white;
  background-color: white;
  border-radius: 15px;
  font-weight: 600;
}

.line-downSub {
  margin: 0 1rem;
}

.text-box-downSub {
  text-align: left;
  font: normal normal 600 16px/24px Poppins;
  letter-spacing: 0px;
  color: #003a6f;
  opacity: 1;
  margin: 2rem 0;
}

.button-box-downSub {
  color: #003a6f;
  font-weight: 600;
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  padding: 1rem 3rem;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 5rem;
  text-align: center;
}

.button-box-downSub-disabled {
  color: #003a6f;
  font-weight: 600;
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 0.5;
  padding: 1rem 3rem;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 5rem;
  pointer-events: none;
  text-align: center;
}

.left-Col-text-1-downSub {
  text-align: left;
  font: normal normal bold 16px/10px Poppins;
  letter-spacing: 0.32px;
  color: #003a6f;
  opacity: 1;
  margin: 1rem 0;
  font-size: 15px;
}

.AU-SU-downSub {
  border: 1px solid #ecf6ff;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 1rem;
  margin-bottom: 1rem;
}

.AU-SU-Text-downSub {
  color: var(--unnamed-color-003a6f);
  text-align: left;
  font: normal normal medium 16px/20px Poppins;
  letter-spacing: 0px;
  color: #003a6f;
  opacity: 1;
  margin: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 22rem;
}

.AU-SU-TextEmail-downSub {
  margin-left: 1.75rem;
  font-size: 0.8rem;
  font: normal normal normal 14px/20px Poppins;
  letter-spacing: 0px;
  color: #5985ac;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 22rem;
}

.AO-RB-AO {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
  padding: 1.4rem;
  margin-bottom: 1rem;
  // width: 70%;
  height: 75px;
  cursor: pointer;
}

.AO-RB-Text-AO {
  text-align: left;
  font: normal normal 600 15px/10px Poppins;
  letter-spacing: 0px;
  color: #001221;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.image-blue-arrow {
  position: absolute;
  right: 3rem;
  top: 2rem;
}

.newImg {
  border: 1px solid #c3dbf3;
  border-radius: 7px;
  background-color: #c3dbf3;
}

.ETA-box {
  display: flex;
  justify-content: space-around;
  font-size: 13px;
  font-weight: 600;
}

.new-ETA-box {
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 0.5rem 0;
  color: #003a6f !important;
  font-size: 1.02rem !important;
  font-weight: 600 !important;
  margin-bottom: 0;
}

.map-ETA-box {
  background-color: white;
  margin: 0.75rem 0;
  padding: 1rem 0rem;
  border-radius: 0.75rem;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
}

.high-map-ETA-box {
  background-color: #003a6f;
  margin: 0.75rem 0;
  padding: 1rem 0rem;
  border-radius: 0.75rem;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  color: white;
}

.occcurance-box {
  margin-top: 1.5rem;
}

.new-val-table {
  overflow: hidden;
  height: 17rem;
  overflow-y: auto;
  padding: 0 1rem;
}

.new-val-table-3 {
  overflow: hidden;
  height: 13rem;
  overflow-y: auto;
  padding: 0 1rem;
}

.new-val-table-2 {
  overflow: hidden;
  height: 9rem;
  overflow-y: auto;
  padding: 0 1rem;
}

.new-val-table-1 {
  overflow: hidden;
  height: 5rem;
  overflow-y: auto;
  padding: 0 1rem;
}

.noData {
  margin: 1rem;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 800;
}

.positonFamily {
  margin-bottom: 0.75rem;
}

.positonFamily-1 {
  margin-bottom: 0.25rem;
}

.new-dsgn-1 {
  display: flex;
  justify-content: left;
  margin-top: 0.25rem;
}

.new-dsgn-2 {
  display: flex;
  justify-content: left;
  margin-top: 1rem;
}

.pos-2 {
  font-size: 14px;
  font-weight: 700;
  margin-left: 4rem;
}

.po-1 {
  margin-bottom: 0rem;
}

.new-img {
  margin-top: 9.5rem !important;
  margin-bottom: 1.5rem !important;
}

.iframe-style {
  height: 35rem;
  width: 100%;
}

.iframe-loading {
  display: flex;
  margin: auto;
  justify-content: center;
}

.compare-save {
  border: 2px solid black;
  /* padding: 5px; */
  /* display: flex; */
  position: absolute;
  /* padding: 2px 0 2px 2px; */
  padding: 7px;
  left: 8px;
  border-radius: 10px;
}

@media (max-width: 1399px) {
  .newDesignBox {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
@media (min-width: 1399px) {
  .newDesignBox {
    display: flex;
    justify-content: start;
    margin-top: 3rem;
  }
}

.story-card-value {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
  padding: 1rem;
  // width: 25%;
}

.new-Btn-position {
  margin-top: -42px !important;
}

@media (max-width: 992px) {
  .new-Btn-position {
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }
}

@media (max-width: 767px) {
  .new-Btn-position {
    margin-top: 0 !important;
  }

  .no-rem {
    margin-top: 12px;
    // width: 525px;
    height: 25.25rem;
    opacity: 1;
    // position: absolute;
  }

  .famDash-rembox {
    // border: 2px solid #36f1cc;
    border: 2px solid #9ac2e5;

    border-radius: 50px;
    padding: 0.5rem 0.5rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 661px) {
  .switch-family {
    position: absolute;
    bottom: 6rem;
    left: 2rem;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 5px;
    padding: 8px;
    background: #36f1cc 0 0 no-repeat padding-box !important;
    color: white !important;
    cursor: pointer;
    border: 1px solid black;
    width: 7rem;
    text-align: center;
  }

  .switch-family-1 {
    position: absolute;
    bottom: 3rem;
    left: 2rem;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 5px;
    padding: 8px;
    background: #36f1cc 0 0 no-repeat padding-box !important;
    color: white !important;
    cursor: pointer;
    border: 1px solid black;
    width: 7rem;
  }
}

@media (max-width: 661px) {
  .switch-family {
    position: absolute;
    bottom: 7rem;
    left: 2rem;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 5px;
    padding: 8px;
    background: #36f1cc 0 0 no-repeat padding-box !important;
    color: white !important;
    cursor: pointer;
    border: 1px solid black;
    width: 7rem;
    text-align: center;
  }

  .switch-family-1 {
    position: absolute;
    bottom: 4rem;
    left: 2rem;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 5px;
    padding: 8px;
    background: #36f1cc 0 0 no-repeat padding-box !important;
    color: white !important;
    cursor: pointer;
    border: 1px solid black;
    width: 7rem;
  }
}

.card-wrong {
  z-index: 111;
  position: absolute;
  top: -110px;
  width: 22rem;
  background-color: #e04f5f;
  color: white;
  height: 4.5rem;
  left: 4rem;
}

.trial-box {
  margin: 0 0 1.5rem 0;
  font-size: 1rem;
  color: red;
  font-weight: 900;
}

.cancel-btnFD {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  background: #36f1cc 0 0 no-repeat padding-box !important;
  color: #003a6f !important;
  opacity: 1;
  border: 2px solid #dbfff7;
  width: 14rem;
  background-color: #dbfff7;
  padding: 10px 5px;
  cursor: pointer;
  border-radius: 25px;
  margin: auto;
  text-align: center;
  margin-bottom: 1rem;
}

.upgrade-subscription {
  position: absolute;
  top: -7.25rem;
  border: none;
  background-color: #003a6f !important;
  color: white;
  padding: 1rem;
  left: 2.5rem;
  font-size: 14px;
  border-radius: 5px;
}

.dashboard-details {
  border: 1px solid #ecf6ff;
  border-radius: 10px;
}

.dashboard-header {
  font-size: 16px;
  top: 0;
  left: 2rem;
  color: white;
  border-radius: 10px;
  background: #36f1cc 0 0 no-repeat padding-box;
}

.dashboard-header1 {
  font-size: 13px;
  top: 0;
  left: 1rem;
  color: white;
  border-radius: 5px;
  background: #36f1cc 0 0 no-repeat padding-box;
}

.dashboard-subheader {
  font-size: 14px;
  // border: 2px solid #36f1cc;
  border: 2px solid #9ac2e5;

  border-radius: 2.5rem;
}

.dashboard-subheader1 {
  font-size: 13px;
  // border: 2px solid #36f1cc;
  border: 2px solid #9ac2e5;

  border-radius: 5px;
}

.dashboard-login {
  color: white;
  border-radius: 5px;
  background: #36f1cc 0 0 no-repeat padding-box;
  /* padding: 0.5rem 3rem; */
  font-size: 15px;
  cursor: pointer;
  align-items: center;
  display: flex;
  padding: 5px 2rem;
}

.fam-dash-box {
  border-radius: 5px;
  background: #36f1cc 0 0 no-repeat padding-box;
  padding: 0.5rem 2rem;
  cursor: pointer;
  width: fit-content;
  margin: 0 0 0 auto;
}

.famDash-editDlt {
  display: flex;
  /* text-align: center; */
  align-items: center;
  margin: 0px 0.5rem;
  background-color: white;
  padding: 0 0.5rem;
}

.famDash-rembox {
  // border: 2px solid #36f1cc;
  border: 2px solid #9ac2e5;
  border-radius: 50px;
  padding: 0.5rem 0.5rem;
}

.famDash-remMain {
  position: relative;
  padding: 1rem 0 0 0;
}

.famDash-remTop {
  position: absolute;
  top: 0;
  z-index: 1;
  background-color: #36f1cc;
  left: 1rem;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  font-size: 13px;
}

.cards-height {
  height: 11.7rem;
}

.famDash-modal1 {
  text-align: center;
  border: 1px solid #003a6f;
  color: white;
  background-color: #36f1cc;
  width: fit-content;
  margin: auto;
  padding: 0.5rem 1.5rem;
}

.famDash-modal2 {
  text-align: center;
  border: 1px solid #003a6f;
  color: white;
  background-color: #36f1cc;
  width: fit-content;
  margin: 3rem auto auto auto;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
}

.famDash-modal1-box {
  text-align: center;
  border: 1px solid #003a6f;
  color: white;
  background-color: #36f1cc;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
}

.famDash-loginSupp {
  display: flex;
  /* text-align: center; */
  align-items: center;
  margin: 0px 0.5rem;
  background-color: white;
  padding: 0.5rem 2rem;
  font-size: 13px;
  border-radius: 5px;
  color: white !important;
  background-color: #003a6f;
  /* text-decoration: wavy; */
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

@media only screen and (min-width: 992px) {
  .modal-body-line {
    border-right: 2px solid #36f1cc;
  }
}
@media only screen and (max-width: 992px) {
  .modal-body-line {
    border-bottom: 2px solid #36f1cc;
    margin-bottom: 2rem;
  }
}

.delete-iconmodal {
  background-color: white;
  padding: 2.5px 5px;
  cursor: pointer;
}

.login-btn-famdash {
  color: white !important;
  background-color: black;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  font-size: 13px;
  background-color: #003a6f;
}

.famdash-border {
  border: 2px solid #9ac2e5 !important;
  border-radius: 30px !important;
}

.manageuser-btn {
  background: #36f1cc 0 0 no-repeat padding-box !important;
  border-radius: 10px;
  color: #003a6f !important;
  font-size: 0.9rem;
  padding: 10px 2rem;
  box-shadow: none;
  cursor: pointer;
  width: fit-content;
  display: block;
  margin: 0 0 0 auto;
}

.button-style {
  background: #36f1cc 0 0 no-repeat padding-box !important;
  border-radius: 50px;
  color: black;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}

.react-tel-input .form-control {
  color: #5985AC !important;
  width: inherit!important;
  font-family: 'poppins';
}

.intercom-lightweight-app-launcher {
  right: 50px !important;
}

.loader-sidebar{
  border: 6px solid #003a6f;
  border-radius: 50%;
  border-top: 6px solid #dbfff7;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}