// Define variable defaults
$white: #fff;
$black:  #000;
$airforceblue: #5985AC;
$mediumgrey: #6A6A6A;
$navyblue: #003A6F;

.input {
    display: block;
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid;
    background-color: $white;
    font-size: 0.8rem;
    width: 100%;
    border-color: $airforceblue;
    color: $airforceblue !important;

}
.input:focus  {
    border-color: #5985AC !important;
    color: #5985AC !important;
}
.small {
    height: 2.5rem;
    // font-size: 0.875rem;
}
.medium {
    height: 3.15rem;
    // font-size: 1rem;
}
.medium:focus  {
    border-color: #5985AC !important;
    color: #5985AC !important;
}
.large {
    height: 3.5rem;
//     font-size: 1.25rem;
}

.searchInput{
    
    //width: 319px;
    height: 55px;
    background: #ECF6FF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    
    input{
        background-color: transparent;
        border:none;
        font: 14px Poppins;
        color:$navyblue;
        &::placeholder{
            
            color:$navyblue;
        }
    }
}