$light-blue: #ecf6ff;
$reject-blue: #9ac2e5;
$navyblue: #003a6f;

.float-right {
  float: right;
}
.float-left {
  float: left;
}
.listing-cards {
  padding: 1rem;
  background: $light-blue 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  position: relative;
  border: none;
  // width: 80%;
  // float: right;
}
.invite-btn {
  font-weight: 600;
  padding: 10px 15px;
  margin-left: 10px;
  margin-top: 8px;
  font-size: 0.8rem;
  opacity: 1;
  border-radius: 20px;
}
.reject-btn {
  background: $reject-blue 0% 0% no-repeat padding-box;
  border: 1px solid $reject-blue;
  color: #3e5265;
}
.accept-btn {
  background: #dbfff7 0% 0% no-repeat padding-box;
  border: 1px solid #36f1cc;
  color: $navyblue;
}
.card-title {
  text-align: left;
  // width: 300px;
  // letter-spacing: 0px;
  color: $navyblue;
  opacity: 1;
  font-size: 1rem;
  font-weight: 600;
  // margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 26px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.card-subtitle {
  text-align: left;
  letter-spacing: 0px;
  color: #007ef2;
  opacity: 1;
  font-size: 0.8rem;
  margin: 0;
}
.card-smallText {
  text-align: left;
  letter-spacing: 0px;
  color: $navyblue;
  opacity: 1;
  font-size: 0.7rem;
  margin: 0.35rem 0;
}
.card-left {
  float: left;
  //margin: 10px;
  background: $navyblue 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: white;
  padding: 9px 15px;
}
.card-right {
  margin-left: 3.5rem;
}
.card-rollText {
  color: #707070;
  font-size: 0.7rem;
  // margin: 10px 57px 0px 57px;
}
.card-role {
  color: $navyblue;
  font-size: 0.8rem;
  margin-top: 0;
  margin-bottom: 0;
}
.story-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  border-radius: 10px;
  opacity: 1;
  padding: 1rem;
  // width: 25%;
}

.count-heading{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: center;
}
.subscription-txt {
  color: #040008;
  margin: 0;
  font-size: 1rem;
}
// .welcome-card {
//   min-height: 20rem;
// }
// .changeSubscription-cards {
//   min-height: 19.85rem;
// }
// .auPlan-cards {
//   min-height: 19.85rem;
// }
.welcome-card img {
  width: 110px;
}
.welcome-card h3 {
  margin: 12px 0;
  color: $navyblue;
}
.welcome-txt {
  position: relative;
  font-size: 0.76rem;
  color: #001221;
  height: 7.2rem;
}
.benefits-title {
  margin-top: 20px;
}
.benefits {
  font-size: 0.8rem;
  color: #1a0331;
  margin-top: 5px;
  width: max-content;
}
.subscription-img {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background: #ecf6ff 0% 0% no-repeat padding-box;
}
.seat-card h3 {
  margin: 0;
  color: $navyblue;
}
.seat-card p {
  color: #5985ac;
  font-size: 0.85rem;
  margin: 5px 0 25px 0;
}
.status-txt {
  // padding: 1rem 0;
  font-size: 0.75rem;
  color: #007ef2;
}
.member-block {
  height: auto;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 1rem;
  margin-top: 1rem;
}
.member-block .profile-avatar {
  width: 50px;
  height: 50px;
}
// .member-block img {
//   width: 100%;
//   padding: 0;
// }
.vertical-dot-icon {
  margin-top: 15px;
  width: 22px;
}
.edit-icon {
  margin-top: 15px;
  width: 22px;
}
.member-block h3 {
  // margin: 5px 0 0 4.8rem;
  color: #003a6f;
  font-size: 1rem !important;
  font-weight: 500 !important;
  opacity: 1;
}
// change subscription plan
//.changeSubscription-cards .default-plan-img {
.default-plan-img {
  background: #ecf6ff 0% 0% no-repeat padding-box !important;
  width: 50px !important;
  display: flex;
  height: 50px !important;
  border-radius: 50%;
}
//.changeSubscription-cards .default-plan-img img{
.default-plan-img img {
  width: 100%;
  max-height: 3rem;
  border-radius: 30px;
}
.user-profile-img {
  background: #ecf6ff 0% 0% no-repeat padding-box !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 30px;
}
.user-profile-img img {
  width: 100%;
  height: 100%;
  max-height: 3rem;
  border-radius: 30px;
}
.changeSubscription-cards h3 {
  margin: 1rem 0 0;
}
.changeSubscription-cards p,
.notification-card p {
  margin: 0;
  font-size: 0.9rem;
}
.changeSubscription-cards .subtitle,
.notification-card .subtitle {
  color: #5985ac !important;
}
.notification-card {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.75rem;
}
.debit-card {
  margin-bottom: 15px;
}
.debit-card .panel p {
  margin-top: 0;
  margin-bottom: 0;
}
.debit-card .panel .panel-body {
  font-size: 0.75rem;
}
.debit-card .float-left {
  margin: 10px;
}
.debit-card .float-right {
  background: #ecf6ff 0% 0% no-repeat padding-box;
  padding: 12px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  margin-right: -8rem;
}
.debit-card .panel .panel-heading {
  font-weight: bold;
}
.debit-card input[type="radio"] {
  width: 20px;
  height: 25px;
}
.user-dropdown::after {
  content: none !important;
}
.user-dropdown:focus {
  box-shadow: none;
}

.dropdown-menu[x-placement="top-start"] {
  margin-bottom: 0 !important;
  /* margin-top: 0; */
}
.count-cards {
  background: #dbfff7 0% 0% no-repeat padding-box;
  padding: 0.5rem 0.9rem;
}
.count-cards .count-heading {
  margin: 0;
  color: #003a6f;
  font-size: 0.7rem;
}
.count-cards .number-txt {
  color: #040008;
  margin: 0.25rem 0;
  font-weight: 600;
  font-size: 1.1rem;
}
// .category-cards {
//   min-height: 153px;
// }
.category-cards .number-txt {
  font-size: 2rem;
  font-weight: 700;
  // margin-top: -3rem;
  color: #003a6f;
}
.supportUser-cards h3 {
  margin: 1rem 0 0;
  font-size: 1.2rem;
}
.supportUser-cards .subtitle {
  margin: 0.15rem 0;
  color: #5985ac;
  min-height: 22px!important;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 26px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.supportUser-cards .card-subtitle {
  margin-top: 1.15rem;
}
.supportUser-cards .side-arrow {
  margin-top: 0.35rem;
  cursor: pointer;
}
.supportUser-cards .side-arrow img {
  width: 7px;
}
.expired-task {
  border-color: #ff2121;
}
.expired-task .date-txt {
  color: #ff2121 !important;
}
.mini-paymentmtd-card {
  position: relative;
  width: 100%;
  height: 100px;
  background: #b7fff1;
  box-shadow: 3px 3px 10px #e9edf0;
  border-radius: 10px;
}
.existing-plan-card {
  position: relative;
  width: 100%;
  height: auto;
  background: #b7fff1;
  box-shadow: 3px 3px 10px #e9edf0;
  border-radius: 10px;
}
.existing-plan-card .plan-primary {
  padding: 0 1rem 0.5rem 1rem;
}
.existing-plan-card .plan-users {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #003A6F;
  padding-bottom: 1rem;
}
.existing-plan-card .plan-users div {
  text-align: left;
  padding-left: 1rem;
}
.existing-plan-card .plan {
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0px;
  padding: 1rem;
  color: #040008;
}
.existing-plan-card .tag {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 36px;
  text-align: right;
  width: fit-content;
  height: 30px;
  margin: 1rem;
  padding: 1rem;
  float: right;
}
.existing-plan-card .tag span {
  text-align: center;
  bottom: 12px;
  position: relative;
  font-size: 14px;
  letter-spacing: 0px;
  color: #003A6F;
}

.downgrade-note {
  position: relative;
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0px;
  color: #003A6F;
  padding: 0.5rem;
}

.remove-users-btn {
  background: #36F1CC 0% 0% no-repeat padding-box;
  border-radius: 36px;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
}

.remove-users-btn.disabled {
  background: #9AC2E5 0% 0% no-repeat padding-box;
  pointer-events: none;
}

.remove-users-btn span {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #003A6F;
}

.outlined-card {
  position: relative;
  box-shadow: 3px 3px 10px #e9edf0;
  border: 2px solid #9ac2e5;
  border-radius: 10px;
}
.auPlan-cards .subHeading {
  color: #5985ac;
  font-size: 0.85rem;
}
.auPlan-cards .subHeading {
  color: #001221;
  font-size: 0.85rem;
}
// warrant list card
.warrantList-cards .category-btn p {
  background: #36f1cc 0% 0% no-repeat padding-box;
  border-radius: 36px;
  opacity: 1;
  width: 7rem;
  text-align: center;
  padding: 0.2rem 0;
  font-size: 0.8rem;
  margin-top: -2rem;
}
.warrantList-cards small {
  color: #5985ac;
  font-size: 0.8rem;
}
.warrantList-cards .expired {
  color: #6a6a6a !important;
}
.warrantList-cards .expired-red {
  color: #ff5c5c !important;
}
.warrantList-cards .tab-txt {
  color: #003a6f;
  font-weight: 600;
  font-size: 0.95rem;
}
.warrantList-cards .status {
  color: #007ef2;
}
.warrantList-cards .removed-status {
  color: #6a6a6a;
}
.warrantList-cards .list-btn p {
  background: #dbfff7 0% 0% no-repeat padding-box;
  border: 1px solid #36f1cc;
  border-radius: 36px;
  opacity: 1;
  padding: 0.5rem;
  width: 12rem;
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0.5rem;
  cursor: pointer;
}
//task card
.supportUser-cards .tag-txt {
  background: #b7fff1 0% 0% no-repeat padding-box;
  border-radius: 36px;
  padding: 0.25rem 1rem;
  font-size: 0.8rem;
}
.supportUser-cards .date-txt {
  color: #007ef2;
  margin: 0 0.5rem;
  font-size: 0.95rem;
}

.add-support-card .panel p {
  margin-top: 0;
  margin-bottom: 0;
}
.add-support-card {
  background-color: #ecf6ff;
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
}
.add-support-card .float-left {
  margin: 3px 10px 0;
}
.add-support-card input[type="radio"] {
  width: 25px;
  height: 35px;
}
.add-support-card .panel-heading {
  color: #003a6f;
}
.add-support-card .panel-body {
  color: #5985ac;
}
.flex-gap {
  gap: 15px;
}
.benefits-card {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #9ac2e5;
  width: 310px;
  border-radius: 10px;
  margin-bottom: 0;
  .card-body {
    padding: 0.75rem 0.75rem 0 0.75rem !important;
  }
  .benefits-card-top {
    background-color: #ecf6ff;
    height: 150px;
    border-radius: 10px;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
  .benefits-card-circle {
    position: absolute;
    margin-top: -40px;
    left: 30px;
    width: 75px;
    height: 75px;
    background: #9ac2e5;
    border-radius: 38px;
    border: 1px solid;
  }
  .benefits-card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    min-height: 50px;
  }
  .link {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    -webkit-box-orient: vertical;
  }
}

.invite-card-heading-title {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #003A6F;
}

.user-block {
  height: auto;
  background: #ecf6ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 0.5rem;
  margin-top: 1rem;
}

.user-block .smallText {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #5985AC;
}

.user-block .user-name {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #003A6F;
}

.custom-control {
  position: absolute;
  top: 5px;
}

.custom-control input {
  width: 25px;
  height: 25px;
}
// notification detail card
.notification-cards .cat-txt {
  color: #007ef2;
  margin: 0;
  font-size: 0.95rem;
}
.el-acc {
  min-height: 18.85rem;
}
.detailed-noti-card {
  min-height: 190px;
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .member-block {
    padding: 0.75rem 0.5rem;
  }
  .member-block img {
    width: 85%;
  }

  .card-rollText {
    font-size: 0.5rem;
  }
  .invite-card-heading {
    min-height: 3rem;
  }
  .status-txt {
    padding: 0.38rem 0 0;
  }
  .warrantList-cards .list-btn p {
    padding: 0.5rem 0 0.5rem;
    width: 8rem;
    font-size: 0.5rem;
  }
  .count-cards .count-heading {
    font-size: 0.65rem;
  }
  .category-cards .count-heading {
    font-size: 0.65rem;
  }
  .debit-card .float-right {
    margin-right: -12rem;
  }
  .category-cards {
    min-height: 90px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1280px) {
  .count-cards .count-heading {
    font-size: 0.65rem;
  }
  .category-cards .count-heading {
    font-size: 0.65rem;
  }
}
@media only screen and (min-device-width: 1280px) and (max-device-width: 1370px) {
  .count-cards .count-heading {
    font-size: 0.65rem;
  }
  .category-cards .count-heading {
    // font-size: 0.65rem;
  }
}

@media only screen and (min-device-width: 768px) {
  .benefits-card {
    width: 320px;
  }
}
@media only screen and (min-device-width: 1024px) {
  .benefits-card {
    width: 280px;
  }
}
@media only screen and (min-device-width: 1366px) {
  .benefits-card {
    width: 312px;
  }
}
/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) and (max-device-width: 1024px) {
  .notification-card {
    margin-bottom: 0.95rem;
    padding: 0.87rem 0.5rem;
  }
  .notification-card p {
    font-size: 0.6rem;
  }
  .changeSubscription-cards {
    .col-lg-2 {
      min-width: 25%;
    }
    .col-lg-10 {
      max-width: 70%;
    }
  }
  .family-invitation-card {
    margin: 3rem 0 !important;
  }
  .status-txt {
    padding: 1rem 0 0;
    font-size: 0.3rem;
  }
  .debit-card .float-right {
    margin-right: -9rem;
  }
  .auPlan-cards {
    .col-lg-2 {
      min-width: 20%;
    }
    .col-lg-10 {
      max-width: 70%;
    }
    .p {
      font-size: 0.75rem;
    }
    .default-plan-img {
      width: 35px !important;
      height: 35px !important;
    }
    // .default-plan-img img {
    //    height: 50px 
    // }
  }
  .warrantList-cards .tab-txt {
    font-size: 0.85rem;
  }
  .warrantList-cards .list-btn p {
    width: 6rem;
    font-size: 0.3rem;
  }
  .warrantList-cards small {
    font-size: 0.71rem;
  }
}
