// Define variable defaults
$white: #fff;
$secondary: #3E5265;
$primary: #36F1CC;
$success: #16c244;
$danger: #ff0032;
$black:  #000;
$darkblue: #003A6F;
$lightgrey: #C0DCF5;

.button {
    border: none;
    color: $darkblue;
    // padding: 0.75rem 2.5rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.8rem;
    
    border-radius: 20px;
    cursor: pointer;
}
.primary {
    background-color: $primary;
}
.secondary {
    background-color: $secondary;
    color: $lightgrey;
}
.success {
    background-color: $success;
}
.danger {
    background-color: $danger;
}

.mediumbtn {
    height: 2.75rem;
    font-size: .85rem;
    padding: 0.5rem 25px; //0.5rem 3rem;
    font-weight: 600;
}
.largebtn {
    height: 3.7rem;
    font-size: 0.95rem;
    border-radius: 35px;
    width: 80%;
    // font-weight: 500;
}
.tertiary{
    background: #ECF6FF ;
    font-weight: 400;
}
.blue_tertiary{
    background: #C0DCF5 ;
    font-weight: 400;
}
.danger {
    background: #FFE6E6 !important;
    color: #FF2121 !important;
}

.outline {
    border: 1px solid #007EF2;
    border-radius: 22px;
    color: #007EF2;
    background: #fff;
}
.outlineMedium {
    // height: 2.15rem;
    font-size: .75rem;
    padding: 0.5rem 2rem;
    font-weight: 400;
    margin-top: 1rem;
    // width: max-content;
}
.outlineMedium1 {
    position: relative;
    // height: 2.15rem;
    font-size: .75rem;
    padding: 0.5rem 2rem;
    font-weight: 400;
    margin-top: 1rem;
    // width: max-content;
}
.outlineMedium2 {
    // height: 2.15rem;
    font-size: .75rem;
    padding: 0.5rem 2rem;
    font-weight: 400;
    // width: max-content;
    // margin-left: 50px;
    background-color: #ECF6FF;
}
.info {
    background: #DBFFF7;
    font-weight: 500;
}

.smallbtn{
    font-weight: 500;
    padding: 0.5rem 20px; //0.5rem 3rem;
    font-size: .85rem;
}
