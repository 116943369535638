$red: #FF2121;
$lightred : #FFE6E6;
$lightgreen : #DBFFF7;
$green: #36F1CC; 

.alert-card {
    border: 1px solid;
    border-radius: 3px;
    padding: 0.5rem 1rem;
}
.danger-alert {
    background: $lightred 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    color: $red;
    font-size: 0.8rem;
    text-align: center;
    opacity: 1;
}

.success-alert {
    background: $lightgreen 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    color: $green;
    font-size: 0.8rem;
    text-align: center;
    opacity: 1;
}
.action-alert-card {
    padding: 0.5rem 1rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #9AC2E5;
    border-radius: 10px;
    opacity: 1;
    width: 100%;
    float: right;
}
.resend-btn {
    border: 1px solid var(--unnamed-color-36f1cc);
    background: #DBFFF7 0% 0% no-repeat padding-box;
    border: 1px solid #36F1CC;
    border-radius: 36px;
    opacity: 1;
    color: #003A6F;
    font-weight: 600;
    padding: 10px 15px;
    margin-left: 10px;
    font-size: 0.6rem;
}
.action-left {
    float: left;
    margin-right: 10px;
}
.action-text {
    color: #003A6F;
    font-size: 0.85rem;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 889px) {
    .action-text {
        font-size: 0.75rem;
    }
}
/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) and (max-device-width: 1024px) {
    .action-alert-card {
        width: 100%;
    }
}