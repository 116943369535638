.profilenav{
    width: 1110px;
    height: 59px;
    background: #ECF6FF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    // max-width: 80vw;
    display: flex;
    align-items: center;
  
}
.profilenav-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    width: 160px !important;
    height: 49px;
    background: transparent;
    border-radius: 10px;
    font:  bold 14px Poppins;
    letter-spacing: 0px;
    color:#003A6F;
    margin-right: 35px;
}
.btn-active{
    background: #003A6F 0% 0% no-repeat padding-box;
    color:  #FFFFFF;
}
