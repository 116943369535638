.cardd{
    position: relative;
}
.border-tp{
    border: 1px solid #36f1cc;
    border-bottom: none;
    border-radius: 6px;
    height: 36px;
    padding-top: 20px;
    position: relative;
}

.border-tp::after{
    content: "";
    background-color: #fff;
    left: -2px;
    right: 0;
    top: -1;
    width: 101%;
    height: 15px;
    position: absolute;
}

.card-des{
    background-color: #fff;
    position: absolute;
    left: 50%;    
    top: 0;
    transform: translate(-50%, -50%);
}

.right span{
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}

.right h3 {
    background-color: #fff;
    display: inline-block;
}

.inner-cards{
    background-color: #fff;
}