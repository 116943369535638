.imagepicker{
    padding: 0px;
    position: relative;
    width: 150px;
    height: 150px;

}
.viewphoto-modal-ImgHolder{
    display: flex;
    justify-content: center;
    overflow: hidden;
    // width: 200px;
    height: 20rem;
    border-radius: 15px;
    background: #ECF6FF 0% 0% no-repeat padding-box;
    border: 2px solid #ECF6FF;
    position: relative;
}
.imagepicker-imgHolder{
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 360px;
    height:100%;
    overflow: hidden;
    border: 2px solid #ECF6FF;
}
#imagepicker-img{
   height: 100%;
   position: absolute;
}
.imagepicker-addicon{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #003A6F;
    border-radius: 360px;
    margin:0px;
    cursor: pointer;
}
.imagepicker-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    right:-180px;
    z-index: 10;
    width:183px;
    // height:175px;
    background: #FFFFFF;
    box-shadow: 5px 5px 10px #5985AC33;
    border:1px solid #9AC2E5;
    border-radius:5px;
    opacity:1;
}
.imagepicker-modal>span{
    display: flex;
    align-items: center;
    width: 153px;
    // height: 35px;
  
    border-radius: 5px;
    font: 12px Poppins;
    letter-spacing: 0.24px;
    color: #003A6F;
    padding-left: 15px;
    cursor: pointer;
}
.imagepicker-modal>span:hover{
    background: #ECF6FF;
}